/***
 *
 * ? Local & shared Import
 * @returns
 * @function Hook:::useAuthSignin
 * @function Hook:::useAuthSignup
 */

import { Student } from '@/types/Student';
import { pickErrorMessage, pickResult, useConfig } from '@hooks/useConfig';
import { useStepperActions } from '@hooks/useStepper';
import { useMutation } from '@tanstack/react-query';
import { handleError } from '@utils/error';
import { AxiosError } from 'axios';
import { axios } from 'lib';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export type SignInCredentials = {
  email: string;
  password: string;
};

export type SignUpPayload = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirm_password: string;
};

export type StudentRegResponse = {
  status: string;
  student: any;
};

const useStudentReg = () => {
  const config = useConfig();
  const navigate = useNavigate();
  const { setStep } = useStepperActions();
  const {
    mutate: register,
    data,
    isLoading,
  } = useMutation<StudentRegResponse, AxiosError, Partial<Student>>(
    async (data: Partial<Student>) =>
      await axios
        .post('/student/create', data, config)
        .then((res) => {
          return res.data;
          // return pickResult(res);
        })
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: (data) => {
        toast.success(
          'Registration Successful, Please continue to upload documents'
        );

        setStep({ step: 2, completed: true, active: false });
        navigate(
          `/students-reg/?step=student-documents-upload&formId=${data?.student?.id}`
        );
      },
    }
  );

  return {
    register,
    data,
    isLoading,
  };
};

const useUploadStudentDocument = () => {
  const config = useConfig();
  const navigate = useNavigate();
  const {
    mutate: uploadDocument,
    data,
    isLoading: isFileUploading,
  } = useMutation<StudentRegResponse, AxiosError, Partial<Student>>(
    async (data: Partial<Student>) =>
      await axios
        .post('/student/file/create', data, config)
        .then((res) => pickResult(res))
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: () => {
        toast.success(
          'Upoad Successful! Congrats your registration is now complete'
        );
        navigate('/');
      },
    }
  );

  return {
    uploadDocument,
    data,
    isFileUploading,
  };
};

export { useStudentReg, useUploadStudentDocument };
