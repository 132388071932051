import ProfileModule from '@features/Profile/components/Profile.Module';
import React from 'react';

const Profile = () => {
  return (
    <div>
      <ProfileModule />
    </div>
  );
};

export default Profile;
