import { EtcTypes } from '@/types/Consular';
import Card from '@components/elements/Card';
import Form from '@components/elements/Form';
import FormContainer from '@components/elements/FormContainer';
import Section from '@components/elements/Section';
import Heading from '@components/elements/Text/Heading';
import TextField from '@components/elements/TextField';
import { useFormik } from 'formik';
import SelectDropdown from '@components/elements/Select';
import { GenderOptions } from '@utils/data';
import Button from '@components/elements/Button';
import { useEmergencyTravelCerts } from '@features/Consular/api';
import { useAuth } from '@features/Auth/hooks/useAuthStore';
import { nextOfKinSchema } from '@utils/validationSchema/emergencyTravelSchema';
import { useGetStates } from '@hooks/useStateLga';
import { useMemo } from 'react';
import { getLga } from '@store/mocks';
import { useEtcPayload } from '@features/Consular/hooks/useConsularActions';

const NextOfKinDetails = () => {
  const { handleETCApply, isApplying } = useEmergencyTravelCerts();
  const { id: user_id } = useAuth();
  const { emergencyTravelCerts } = useEtcPayload();

  const intitalValues: Partial<EtcTypes> = {
    kinSurname: '',
    kinOthername: '',
    kinAddress: '',
    kinGender: '',
    kinStateOfOrigin: '',
    kinLga: '',
    kinEmail: '',
    kinOccupation: '',
    kinCountry: '',
    kinRelationship: '',
    kinrefreeName: '',
    refreeCity: '',
    refreePostcode: '' as unknown as number,
    dateFilled: '' as unknown as Date,
  };

  const { handleSubmit, values, handleChange, errors, touched, setFieldValue } =
    useFormik<Partial<EtcTypes>>({
      initialValues: intitalValues,
      validationSchema: nextOfKinSchema,
      onSubmit: async (values: Partial<EtcTypes>) => {
        const payload = { ...values, ...emergencyTravelCerts, user_id };
        handleETCApply(payload);
      },
    });

  const { states } = useGetStates();
  const lga = useMemo(
    () => getLga((values as any)?.kinStateOfOrigin),
    [values]
  );

  return (
    <div className="pb-10">
      <Card className="bg-white">
        <Form onSubmit={handleSubmit}>
          <Heading className={'text-primary mb-8'}>Next Of Kin Details</Heading>
          <Section className="grid grid-3">
            <FormContainer>
              <TextField
                placeholder="Eg; Ndubuisi Olufemi Hassan"
                label="Next of Kin Surname *"
                name="kinSurname"
                value={values.kinSurname}
                error={!!errors?.kinSurname && touched?.kinSurname}
                onChange={handleChange}
                message={errors.kinSurname}
              />
              <TextField
                placeholder="Other Names of Next of Kin *"
                label="Next of Kin Othername *"
                name="kinOthername"
                value={values.kinOthername}
                error={!!errors?.kinOthername && touched?.kinOthername}
                onChange={handleChange}
                message={errors.kinOthername}
              />
              <SelectDropdown
                options={GenderOptions}
                label="Next of Kins Gender *"
                placeholder="Select Next of Kins Gender"
                name="kinGender"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('kinGender', value);
                }}
                defaultValue={values.kinGender}
                error={!!errors.kinGender && touched.kinGender}
                errorMessage={errors.kinGender}
              />
              <TextField
                placeholder="Enter Next of Kin Relationship *"
                label="Next of Kin Relationship *"
                name="kinRelationship"
                value={values.kinRelationship}
                error={!!errors?.kinRelationship && touched?.kinRelationship}
                onChange={handleChange}
                message={errors.kinRelationship}
              />
              <SelectDropdown
                options={states}
                label="Next of Kin State of Origin *"
                placeholder="Select Next of Kin State of Origin"
                name="kinStateOfOrigin"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('kinStateOfOrigin', value);
                }}
                defaultValue={values.kinStateOfOrigin}
                error={!!errors.kinStateOfOrigin && touched.kinStateOfOrigin}
                errorMessage={errors.kinStateOfOrigin}
              />
              <SelectDropdown
                options={lga}
                label="Next of Kin LGA of Origin *"
                placeholder="Select Next of Kin LGA of Origin"
                name="kinLga"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('kinLga', value);
                }}
                defaultValue={values.kinLga}
                error={!!errors.kinLga && touched.kinLga}
                errorMessage={errors.kinLga}
              />
              <TextField
                placeholder="Enter country of Next of Kin"
                label="Next Of Kin's Country *"
                name="kinCountry"
                value={values.kinCountry}
                error={!!errors?.kinCountry && touched?.kinCountry}
                onChange={handleChange}
                message={errors.kinCountry}
              />
              <TextField
                placeholder="Address of Next of Kin *"
                label="Next of Kin Address *"
                name="kinAddress"
                value={values.kinAddress}
                error={!!errors?.kinAddress && touched?.kinAddress}
                onChange={handleChange}
                message={errors.kinAddress}
              />
              <TextField
                placeholder="Enter Next Of Kin Email *"
                label="Next Of Kin Email *"
                name="kinEmail"
                value={values.kinEmail}
                error={!!errors?.kinEmail && touched?.kinEmail}
                onChange={handleChange}
                message={errors.kinEmail}
              />
              <TextField
                placeholder="Eg; Medical doctor"
                label="Next of Kin Occupation *"
                name="kinOccupation"
                value={values.kinOccupation}
                error={!!errors?.kinOccupation && touched?.kinOccupation}
                onChange={handleChange}
                message={errors.kinOccupation}
              />
              <TextField
                placeholder="Enter Next of Kin Referee Name"
                label="Next of Kin Referee Name *"
                name="kinrefreeName"
                value={values.kinrefreeName}
                error={!!errors?.kinrefreeName && touched?.kinrefreeName}
                onChange={handleChange}
                message={errors.kinrefreeName}
              />
              <TextField
                placeholder="Enter Next of Kin Referee City"
                label="Next of Kin Referee City *"
                name="refreeCity"
                value={values.refreeCity}
                error={!!errors?.refreeCity && touched?.refreeCity}
                onChange={handleChange}
                message={errors.refreeCity}
              />
              <TextField
                placeholder="Enter Next of Kin Post Code"
                label="Next of Kin Referee Post Code *"
                name="refreePostcode"
                value={values.refreePostcode}
                error={!!errors?.refreePostcode && touched?.refreePostcode}
                onChange={handleChange}
                message={errors.refreePostcode}
              />
              <TextField
                placeholder="Enter date filled"
                label="Date Filled *"
                type="date"
                name="dateFilled"
                value={values.dateFilled}
                error={!!errors?.dateFilled && touched?.dateFilled}
                onChange={handleChange}
                message={errors.dateFilled}
              />
            </FormContainer>

            <Section className="mt-10">
              <Button
                className="bg-primary text-white lg:w-56 w-full"
                type="submit"
                label="Next"
                loading={isApplying}
              />
            </Section>
          </Section>
        </Form>
      </Card>
    </div>
  );
};

export default NextOfKinDetails;
