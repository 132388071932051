import React, { SetStateAction, useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import Button from '../Button';
import FormLabel from '../Text/FormLabel';
import Section from '../Section';
import Image from '../Image';
import { docsUpload } from '@assets/icons';
import { FieldInputProps } from 'formik';

interface UploadProps {
  url: any;
  label: string;
  setUrl: React.Dispatch<SetStateAction<any>>;
  error?: boolean;
  name: string;
  errorMessage: string | undefined;
  setFieldError: (field: string, message?: string | undefined) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  inputProps?: FieldInputProps<any>;
}

const Uploader: React.FC<UploadProps> = ({
  url,
  label,
  setUrl,
  error,
  setFieldError,
  setFieldValue,
  inputProps,
  name,
  errorMessage,
}) => {
  const [fileError, setFileError] = useState<string | undefined>(undefined);
  const [showDocs, setShowDocs] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      acceptedFiles.forEach((file) => {
        if (!file.type.includes('pdf') && !file.type.includes('docx')) {
          setFieldValue(name, file);
          setFieldError(name, undefined);
          setUrl(file);
        } else if (file.type.includes('pdf') || file.type.includes('docx')) {
          setShowDocs(true);
          setFileName(file?.name);
          setUrl(file);
          setFieldValue(name, file);
          setFieldError(name, undefined);
        } else {
          setFieldError(name, 'Invalid file format for' + name);
        }
      });
    },
    [name, setFieldError, setFieldValue, setUrl]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      maxSize: 10485760, // 10 MB in bytes
      accept: {
        'application/pdf': ['.pdf'],
        'application/msword': ['.docx'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpg, jpeg'],
        'image/webp': ['.webp'],
      },
    });

  const removeFile = () => {
    setUrl(null);
    setFileError(undefined);
    setShowDocs(false);
  };

  return (
    <div>
      <div className="mb-4">
        <FormLabel
          title={label}
          error={isDragReject || error || fileError !== undefined}
        />
      </div>
      <div
        className={[
          ' border border-dashed p-16 rounded-2xl ',
          isDragActive
            ? 'border-[#82C600] bg-indigo-200'
            : 'border-[#C9C9C9] bg-neutral-accorion',
          isDragReject || error || fileError !== undefined ? 'bg-error' : null,
        ].join(' ')}
      >
        <div {...getRootProps()}>
          <input name={name} {...getInputProps()} {...inputProps} />
          {isDragActive && <p className="text-center">Uploading File...</p>}
          {url && url.type && url.type.includes('image') && (
            <div className="relative lg:w-96">
              <img src={URL.createObjectURL(url)} alt="Uploads" />
              <button
                className="absolute top-1 right-1 rounded-full bg-black text-white w-6 h-6 flex justify-center items-center"
                onClick={removeFile}
              >
                X
              </button>
            </div>
          )}
          {showDocs && (
            <Section className="flex items-center">
              <Section className="w-8">
                <Image
                  src={docsUpload}
                  alt="Uploaded file"
                  className="w-full"
                />
              </Section>
              <Section className="ml-4">{fileName}</Section>
            </Section>
          )}
          {!url && !fileName && (
            <div className="flex items-center justify-center">
              <Button
                className="border-[#C9C9C9] border px-4 lg:w-64 rounded-lg"
                label="Upload from computer"
              />
              {/* <Button
                    className="border-[#C9C9C9] border px-4 w-64 rounded-lg ml-4"
                    label="Google Drive"
                /> */}
            </div>
          )}
          {errorMessage && (
            <p className="text-center text-red-500">{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Uploader;
