import { PoliceClearanceTypes } from '@/types/Consular';
import Card from '@components/elements/Card';
import Form from '@components/elements/Form';
import FormContainer from '@components/elements/FormContainer';
import Section from '@components/elements/Section';
import Heading from '@components/elements/Text/Heading';
import TextField from '@components/elements/TextField';
import { personalDetailsSchema } from '@utils/validationSchema/policeClearanceSchema';
import { useFormik } from 'formik';
import SelectDropdown from '@components/elements/Select';
import { EducationStatusOptions, MaritalStatusOptions } from '@utils/data';
import Button from '@components/elements/Button';
import { useConsularActions } from '../../hooks/useConsularActions';
import { usePoliceClearance } from '@features/Consular/api';
import { useAuth } from '@features/Auth/hooks/useAuthStore';

const PersonalDetails = () => {
  const { setPoliceClearancePayload } = useConsularActions();

  const { register, isLoading } = usePoliceClearance();
  const { id: user_id } = useAuth();

  const intitalValues: Partial<PoliceClearanceTypes> = {
    fullName: '',
    maidenName: '',
    address: '',
    telNo: '',
    country: '',
    nationality: '',
    dob: '' as unknown as Date,
    placeOfBirth: '',
    educationStatus: '',
    maritalStatus: '',
    passportNumber: '',
    addressInNig: '',
    dateLastResidenceInNig: '' as unknown as Date,
    presentEmployment: '',
    fatherName: '',
    fatherAddress: '',
    fatherOcupation: '',
    dateFilled: '' as unknown as Date,
  };

  const { handleSubmit, values, handleChange, errors, touched, setFieldValue } =
    useFormik<Partial<PoliceClearanceTypes>>({
      initialValues: intitalValues,
      validationSchema: personalDetailsSchema,
      onSubmit: async (values: Partial<PoliceClearanceTypes>) => {
        setPoliceClearancePayload(values as PoliceClearanceTypes);
        register({ ...values, user_id });
      },
    });

  return (
    <div className="pb-10">
      <Card className="bg-white">
        <Form onSubmit={handleSubmit}>
          <Heading className={'text-primary mb-8'}>
            Certificate of Good Conduct Biographic Details
          </Heading>

          <Section className="grid grid-3">
            <FormContainer>
              <TextField
                placeholder="Eg; Olufemi Chukwudi Hassan"
                label="Full Name *"
                name="fullName"
                value={values.fullName}
                error={!!errors?.fullName && touched?.fullName}
                onChange={handleChange}
                message={errors.fullName}
              />
              <TextField
                placeholder="Maiden name (if any)"
                label="Maiden Name"
                name="maidenName"
                value={values.maidenName}
                error={!!errors?.maidenName && touched?.maidenName}
                onChange={handleChange}
                message={errors.maidenName}
              />

              <TextField
                placeholder="Enter full address"
                label="Address *"
                name="address"
                value={values.address}
                error={!!errors?.address && touched?.address}
                onChange={handleChange}
                message={errors.address}
              />

              <TextField
                placeholder="+234XXXXXXXXX"
                label="Phone Number"
                type="text"
                name="telNo"
                value={values.telNo}
                error={!!errors.telNo && touched.telNo}
                message={errors.telNo}
                onChange={handleChange}
              />

              <TextField
                placeholder="Enter country"
                label="Country *"
                name="country"
                value={values.country}
                error={!!errors?.country && touched?.country}
                onChange={handleChange}
                message={errors.country}
              />

              <TextField
                placeholder="Eg; Nigerian"
                label="Nationality *"
                name="nationality"
                value={values.nationality}
                error={!!errors?.nationality && touched?.nationality}
                onChange={handleChange}
                message={errors.nationality}
              />

              <TextField
                placeholder="Enter date of birth"
                label="Date of Birth *"
                type="date"
                name="dob"
                value={values.dob}
                error={!!errors?.dob && touched?.dob}
                onChange={handleChange}
                message={errors.dob}
              />

              <TextField
                placeholder="Enter place of birth"
                label="Place of birth *"
                name="placeOfBirth"
                value={values.placeOfBirth}
                error={!!errors?.placeOfBirth && touched?.placeOfBirth}
                onChange={handleChange}
                message={errors.placeOfBirth}
              />

              <SelectDropdown
                options={[...EducationStatusOptions]}
                label="Education Status *"
                name="educationStatus"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('educationStatus', value);
                }}
                defaultValue={values.educationStatus}
                error={!!errors.educationStatus && touched.educationStatus}
                errorMessage={errors.educationStatus}
              />

              <SelectDropdown
                options={[...MaritalStatusOptions]}
                label="Marital Status *"
                name="maritalStatus"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('maritalStatus', value);
                }}
                defaultValue={values.maritalStatus}
                error={!!errors.maritalStatus && touched.maritalStatus}
                errorMessage={errors.maritalStatus}
              />

              <TextField
                placeholder="Enter passport number"
                label="Passport Number *"
                name="passportNumber"
                value={values.passportNumber}
                error={!!errors?.passportNumber && touched?.passportNumber}
                onChange={handleChange}
                message={errors.passportNumber}
              />

              <TextField
                placeholder="Enter address in Nigeria"
                label="Address when in Nigeria in Full *"
                name="addressInNig"
                value={values.addressInNig}
                error={!!errors?.addressInNig && touched?.addressInNig}
                onChange={handleChange}
                message={errors.addressInNig}
              />

              <TextField
                placeholder="Enter last date"
                label="Last Date In Nigeria *"
                type="date"
                name="dateLastResidenceInNig"
                value={values.dateLastResidenceInNig}
                error={
                  !!errors?.dateLastResidenceInNig &&
                  touched?.dateLastResidenceInNig
                }
                onChange={handleChange}
                message={errors.dateLastResidenceInNig}
              />

              <TextField
                placeholder="Present employment"
                label="Present Employment *"
                name="presentEmployment"
                value={values.presentEmployment}
                error={
                  !!errors?.presentEmployment && touched?.presentEmployment
                }
                onChange={handleChange}
                message={errors.presentEmployment}
              />

              <TextField
                placeholder="Eg; Ndubuisi Olufemi Hassan"
                label="Father's Name *"
                name="fatherName"
                value={values.fatherName}
                error={!!errors?.fatherName && touched?.fatherName}
                onChange={handleChange}
                message={errors.fatherName}
              />

              <TextField
                placeholder="Eg; 5 Jibowu Aba road"
                label="Father's Address *"
                name="fatherAddress"
                value={values.fatherAddress}
                error={!!errors?.fatherAddress && touched?.fatherAddress}
                onChange={handleChange}
                message={errors.fatherAddress}
              />

              <TextField
                placeholder="Eg; Medical doctor"
                label="Father's Occupation *"
                name="fatherOcupation"
                value={values.fatherOcupation}
                error={!!errors?.fatherOcupation && touched?.fatherOcupation}
                onChange={handleChange}
                message={errors.fatherOcupation}
              />

              <TextField
                placeholder="Enter date filled"
                label="Date Filled *"
                type="date"
                name="dateFilled"
                value={values.dateFilled}
                error={!!errors?.dateFilled && touched?.dateFilled}
                onChange={handleChange}
                message={errors.dateFilled}
              />
            </FormContainer>

            <Section className="mt-10">
              <Button
                className="bg-primary text-white lg:w-56 w-full"
                type="submit"
                label="Next"
                loading={isLoading}
              />
            </Section>
          </Section>
        </Form>
      </Card>
    </div>
  );
};

export default PersonalDetails;
