import AboutUniversity from './components/AboutUniversity';
import StudentsDocumentUpload from './components/StudentsDocumentUpload';
import PersonalInfo from './components/PersonalInfo';
import Terms from './components/Terms';
import { useSearchParams } from 'react-router-dom';
import Section from '@components/elements/Section';
import Span from '@components/elements/Span';
import Paragraph from '@components/elements/Text/Paragraph';
// import Stepper from '@components/widgets/stepper/Stepper';

const StudentsModule = () => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get('step') ?? 'about-university';

  const routes: { [key: string]: JSX.Element } = {
    'about-university': <AboutUniversity />,
    'student-documents-upload': <StudentsDocumentUpload />,
    'personal-info': <PersonalInfo />,
    terms: <Terms />,
  };

  const routeHeader: { [key: string]: { title: string; description: string } } =
    {
      'about-university': {
        title: 'Student Registration',
        description: 'Academic Details',
      },
      'student-documents-upload': {
        title: 'Student Registration',
        description: 'Document uploads',
      },
      'personal-info': {
        title: 'Student Registration',
        description: 'Biodata',
      },
      terms: { title: 'Student Registration', description: 'Biodata' },
    };

  return (
    <Section>
      {/* <Section className="mb-6">
        <Stepper />
      </Section> */}
      <Section className="my-4">
        <Paragraph className="text-neutral-copy-black text-lg">
          {routeHeader[step].title} -{' '}
          <Span className="font-light text-sm">
            {routeHeader[step].description}
          </Span>
        </Paragraph>
      </Section>
      {routes[step]}
    </Section>
  );
};

export default StudentsModule;
