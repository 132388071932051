import Section from '@components/elements/Section';
import Span from '@components/elements/Span';
import Paragraph from '@components/elements/Text/Paragraph';
import { useSearchParams } from 'react-router-dom';
import UploadDocuments from './UploadDocuments';
import { ReactComponent as ProfileIcon } from '../../../../assets/icons/editable-profile-icon.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/editable-upload-icon.svg';
import { ReactComponent as CardIcon } from '../../../../assets/icons/editable-card-icon.svg';
import ClearancePayment from './ClearancePayment';
import PersonalDetails from './PersonalDetails';

const PoliceClearanceModule = () => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get('step') ?? 'personal-details';

  const routes: { [key: string]: JSX.Element } = {
    'upload-documents': <UploadDocuments />,
    payment: <ClearancePayment />,
    'personal-details': <PersonalDetails />,
  };

  const routeHeader: { [key: string]: { title: string; description: string } } =
    {
      'upload-documents': {
        title: 'Police Clearance',
        description: 'Document Uploads',
      },
      payment: {
        title: 'Police Clearance',
        description: 'Payment',
      },
      'personal-details': {
        title: 'Police Clearance',
        description: 'Biographic Details',
      },
    };

  return (
    <Section>
      <Section className="my-4">
        <Paragraph className="text-neutral-copy-black text-lg">
          {routeHeader[step].title} -{' '}
          <Span className="font-light text-sm">
            {routeHeader[step].description}
          </Span>
        </Paragraph>
      </Section>
      <Section className="my-4 grid grid-cols-3">
        <div className="py-3 flex items-center border-b-2 border-primary">
          <ProfileIcon
            width={24}
            height={24}
            className="mr-2"
            stroke="#227645"
          />
          <span className="text-sm">Personal Details</span>
        </div>
        <div className="py-3 flex items-center border-b-2 border-primary">
          <UploadIcon width={16} height={16} className="mr-2" fill="#404040" />
          <span className="text-sm">Upload Documents</span>
        </div>
        <div className="py-3 flex items-center border-b-2 border-primary">
          <CardIcon width={16} height={16} className="mr-2" fill="#E0E0E0" />
          <span className="text-sm">Payment</span>
        </div>
      </Section>
      {routes[step]}
    </Section>
  );
};

export default PoliceClearanceModule;
