import { pickErrorMessage, useConfig } from '@hooks/useConfig';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axios } from 'lib';
import { handleError } from '@utils/error';
import { toast } from 'react-toastify';
import { useAuth, useAuthActions } from '@features/Auth/hooks/useAuthStore';
import { ChildProps, UserUpdate } from '@/types/User';

export type StudentRegResponse = {
  status: string;
  user: any;
};

const useUpdateProfile = () => {
  const config = useConfig();
  const user = useAuth();
  const { setUser } = useAuthActions();
  const {
    mutate: update,
    data,
    isLoading,
  } = useMutation<StudentRegResponse, AxiosError, Partial<UserUpdate>>(
    async (data: Partial<UserUpdate>) =>
      await axios
        .post(`/user/update/${data.user_id}`, data, config)
        .then((res) => res.data)
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: (data) => {
        const updated_user = data.user;
        setUser({ ...user, ...updated_user });
        toast.success('User profile updated successfully.');
      },
    }
  );
  return {
    update,
    data,
    isLoading,
  };
};

const useAddChild = () => {
  const config = useConfig();
  const {
    mutate: addChild,
    data: childResponse,
    isLoading: isAdding,
  } = useMutation<StudentRegResponse, AxiosError, ChildProps>(
    async (data: ChildProps) =>
      await axios
        .post(`/sibling/create`, data, { ...config })
        .then((res) => {
          return res.data;
        })
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: (data) => {
        toast.success('Child added successfully.');
      },
    }
  );

  return {
    addChild,
    childResponse,
    isAdding,
  };
};

const useFetchChildren = () => {
  const config = useConfig();
  const fetchChildren = async () => {
    try {
      const response = await axios.get('/siblings', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const { data: children, isFetching: fetchingChildren } = useQuery({
    queryKey: ['children'],
    queryFn: fetchChildren,
    onError: (err) =>
      toast.error(
        typeof err === 'string'
          ? err
          : 'Something went wrong fetching status list'
      ),
  });

  return { children, fetchingChildren };
};

export { useUpdateProfile, useAddChild, useFetchChildren };
