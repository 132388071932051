import Card from '@components/elements/Card';
import Heading from '@components/elements/Text/Heading';
import TextField from '@components/elements/TextField';
// import SelectDropdown from '@components/elements/Select';
import TextArea from '@components/elements/TextArea';
import FormContainer from '@components/elements/FormContainer';
import Section from '@components/elements/Section';
import { Student } from '@/types/Student';
import { useFormik } from 'formik';
import { aboutUniSchema } from '@utils/validationSchema';
import Button from '@components/elements/Button';
import Form from '@components/elements/Form';
import { useStudentRegActions } from '../hooks/useStudentRegActions';
import CheckBox from '@components/elements/CheckBox';
import Paragraph from '@components/elements/Text/Paragraph';
import { useStudentReg } from '../api';
import { useAuth } from '@features/Auth/hooks/useAuthStore';

const AboutUniversity = () => {
  const intitalValues: Partial<Student> = {
    studentNo: '',
    schoollocation: '',
    schooltown: '',
    university: '',
    discipline: '',
    course: '',
    group: '',
    courseStartDate: '' as unknown as Date,
    courseEndDate: '' as unknown as Date,
    aboutYou: '',
    postcode: '',
    declare: false,
  };

  const { setStudentRegPayload } = useStudentRegActions();
  const { register, isLoading } = useStudentReg();
  const { id: user_id } = useAuth();

  const { handleSubmit, values, handleChange, errors, touched, setFieldValue } =
    useFormik<Partial<Student>>({
      initialValues: intitalValues,
      validationSchema: aboutUniSchema,
      onSubmit: async (values: Partial<Student>) => {
        setStudentRegPayload(values as Student);
        const declare = values.declare ? 1 : 0;
        values.declare = declare;
        register({ ...values, user_id });
      },
    });

  return (
    <div className="pb-10">
      <Card className="bg-white">
        <Form onSubmit={handleSubmit}>
          <Section>
            <Heading className={'text-primary mb-6'}>
              About Your University and Course Details
            </Heading>
            <Section className="grid grid-3">
              <Section className="mb-4">
                <FormContainer>
                  <TextField
                    placeholder="Group country Location"
                    label="Check for group Country Location"
                    name="group"
                    value={values.group}
                    onChange={handleChange}
                    error={!!errors.group && touched.group}
                    message={errors.group}
                  />
                  <TextField
                    placeholder="A23 XXX XXX XXX"
                    label="Student No"
                    value={values.studentNo}
                    name="studentNo"
                    onChange={handleChange}
                    error={!!errors.studentNo && touched.studentNo}
                    message={errors.studentNo}
                  />
                </FormContainer>
              </Section>
              <Section className="mb-4">
                <FormContainer>
                  <TextField
                    placeholder="Enter Insitutions Name"
                    label="University *"
                    type="text"
                    value={values.university}
                    onChange={handleChange}
                    name="university"
                    error={!!errors.university && touched.university}
                    message={errors.university}
                  />
                  <TextField
                    placeholder="Enter Schools Location here"
                    label="School Location *"
                    type="text"
                    name="schoollocation"
                    value={values.schoollocation}
                    onChange={handleChange}
                    error={!!errors.schoollocation && touched.schoollocation}
                    message={errors.schoollocation}
                  />
                </FormContainer>
              </Section>
              <Section className="mb-4">
                <FormContainer>
                  <TextField
                    placeholder="Enter School City here"
                    label="School City *"
                    type="text"
                    name="schooltown"
                    value={values.schooltown}
                    onChange={handleChange}
                    error={!!errors.schooltown && touched.schooltown}
                    message={errors.schooltown}
                  />
                  <TextField
                    placeholder="Enter Postcode"
                    label="Postcode *"
                    type="text"
                    value={values.postcode}
                    onChange={handleChange}
                    name="postcode"
                    error={!!errors.postcode && touched.postcode}
                    message={errors.postcode}
                  />
                </FormContainer>
              </Section>
              <Section className="mb-4">
                <FormContainer>
                  {/* <SelectDropdown options={[]} label="Discipline" /> */}
                  {/* <SelectDropdown options={[]} label="Course" /> */}
                  <TextField
                    placeholder="Enter your Department of study here"
                    label="Discipline *"
                    type="text"
                    name="discipline"
                    value={values.discipline}
                    onChange={handleChange}
                    error={!!errors.discipline && touched.discipline}
                    message={errors.discipline}
                  />
                  <TextField
                    placeholder="Enter course of study"
                    label="Course *"
                    type="text"
                    name="course"
                    value={values.course}
                    onChange={handleChange}
                    error={!!errors.course && touched.course}
                    message={errors.course}
                  />
                </FormContainer>
              </Section>
              <Section className="mb-4">
                <FormContainer>
                  <TextField
                    placeholder="Start Date"
                    label="Course Start Date *"
                    type="date"
                    name="courseStartDate"
                    value={values.courseStartDate}
                    onChange={handleChange}
                    error={!!errors.courseStartDate && touched.courseStartDate}
                    message={errors.courseStartDate}
                  />
                  <TextField
                    placeholder="Course End Date *"
                    label="Course End Date *"
                    type="date"
                    name="courseEndDate"
                    value={values.courseEndDate}
                    onChange={handleChange}
                    error={!!errors.courseEndDate && touched.courseEndDate}
                    message={errors.courseEndDate}
                  />
                </FormContainer>
              </Section>
              <Section>
                <TextArea
                  placeholder="You can specify the exact module or course if not listed above and any other information about you"
                  label="Any Other Information about you"
                  onChange={handleChange}
                  textAreaName="aboutYou"
                  value={values.aboutYou}
                />
              </Section>
              <Section className="flex items-center justify-between my-6">
                <Section className="self-start">
                  <CheckBox
                    className="h-4 w-4 md:w-5 md:h-5 checkbox-success text-white rounded"
                    name={'declare'}
                    checked={values.declare as boolean}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.checked);
                    }}
                  />
                </Section>
                <Paragraph className="ml-3 text-neutral">
                  I declare that the information above is correct, and that I
                  may be disqualified from any services if discovered otherwise
                  also note that if your picture is not the required one your
                  details will be deleted.
                </Paragraph>
              </Section>
              {errors.declare && (
                <Paragraph className="text-sm text-red-400">
                  {errors.declare}
                </Paragraph>
              )}
              <Section className="flex items-center my-6">
                <Button
                  label="Next"
                  className="bg-primary text-white lg:w-64"
                  type="submit"
                  loading={isLoading}
                />
              </Section>
            </Section>
          </Section>
        </Form>
      </Card>
    </div>
  );
};

export default AboutUniversity;
