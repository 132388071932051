import {
  EtcTypes,
  NonImpedimentTypes,
  PoliceClearanceTypes,
} from '@/types/Consular';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/types';

export interface ConsularState {
  policeClearancePayload: PoliceClearanceTypes | null;
  nonImpedimentPayload: NonImpedimentTypes | null;
  emergencyTravelCerts: EtcTypes | null;
}

const initialState: ConsularState = {
  policeClearancePayload: null,
  nonImpedimentPayload: null,
  emergencyTravelCerts: null,
};

export const ConsularSlice = createSlice({
  name: 'police-clearance-payload',
  initialState,
  reducers: {
    setPoliceClearancePayload: (
      state: ConsularState,
      { payload }: PayloadAction<PoliceClearanceTypes>
    ) => {
      state.policeClearancePayload = {
        ...state.policeClearancePayload,
        ...payload,
      };
    },
    setNonImpedimentPayload: (
      state: ConsularState,
      { payload }: PayloadAction<NonImpedimentTypes>
    ) => {
      state.nonImpedimentPayload = {
        ...state.nonImpedimentPayload,
        ...payload,
      };
    },
    setEtcPayload: (
      state: ConsularState,
      { payload }: PayloadAction<EtcTypes>
    ) => {
      state.emergencyTravelCerts = {
        ...state.emergencyTravelCerts,
        ...payload,
      };
    },
  },
});

export const {
  setPoliceClearancePayload,
  setNonImpedimentPayload,
  setEtcPayload,
} = ConsularSlice.actions;

export const policeClearancePayload = (state: ConsularState) =>
  state.policeClearancePayload;
export const nonImpedimentPayload = (state: ConsularState) =>
  state.nonImpedimentPayload;
export const etcPayload = (state: RootState) => state.consular;

export default ConsularSlice;
