import { User } from '@/types/User';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/types';

export interface AuthState {
  user: User | null;
}

const initialState: AuthState = {
  user: null,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = AuthSlice.actions;
export const user = (state: RootState) => state.auth.user;
export default AuthSlice;
