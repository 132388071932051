import { ChildProps } from '@/types/User';
import Button from '@components/elements/Button';
import Form from '@components/elements/Form';
import FormContainer from '@components/elements/FormContainer';
import Modal from '@components/elements/Modal';
import Section from '@components/elements/Section';
import SelectDropdown from '@components/elements/Select';
import Paragraph from '@components/elements/Text/Paragraph';
import SubHeading from '@components/elements/Text/SubHeading';
import TextField from '@components/elements/TextField';
import { GenderOptions } from '@utils/data';
import { childValidation } from '@utils/validationSchema';
import { useFormik } from 'formik';
import { useAddChild } from '../api';
import { useAuth } from '@features/Auth/hooks/useAuthStore';

interface AddChildProps {
  handleClose: () => void;
}

const AddChild = ({ handleClose }: AddChildProps) => {
  const auth = useAuth();
  const initialValues: ChildProps = {
    firstname: '',
    lastname: '',
    dob: '',
    pob: '',
    gender: '' as unknown as 'male',
    user_id: auth?.id,
  };

  const { addChild, childResponse, isAdding } = useAddChild();

  const { handleSubmit, values, handleChange, errors, touched, setFieldValue } =
    useFormik<ChildProps>({
      initialValues: initialValues,
      validationSchema: childValidation,
      onSubmit: async (values: ChildProps) => {
        addChild(values);
        if (childResponse?.status == '201') {
          setTimeout(() => handleClose(), 5000);
        }
      },
    });

  return (
    <Modal handleClose={handleClose} width="w-full lg:w-[500px]">
      <Section>
        <Section className="mb-8">
          <SubHeading text="Add Child" className="text-primary mb-1" />
          <Paragraph className="text-neutral text-sm font-light mb-6">
            Fill this form to add your child's detail
          </Paragraph>
        </Section>
        <Form onSubmit={handleSubmit}>
          <Section className=" my-6">
            <FormContainer className="my-6">
              <TextField
                placeholder="Eg. Olufemi"
                label="First Name *"
                name="firstname"
                value={values.firstname}
                error={!!errors?.firstname && touched?.firstname}
                onChange={handleChange}
                message={errors.firstname}
              />
              <TextField
                placeholder="Eg. Chukwudi"
                label="Last Name *"
                name="lastname"
                value={values.lastname}
                error={!!errors?.lastname && touched?.lastname}
                onChange={handleChange}
                message={errors.lastname}
              />
            </FormContainer>

            <Section>
              <TextField
                placeholder="Enter date of birth"
                label="Date of Birth *"
                type="date"
                name="dob"
                value={values.dob}
                error={!!errors?.dob && touched?.dob}
                onChange={handleChange}
                message={errors.dob}
              />
              <Section className="my-6">
                <TextField
                  placeholder="Enter place of birth"
                  label="Place of birth *"
                  name="pob"
                  value={values.pob}
                  error={!!errors?.pob && touched?.pob}
                  onChange={handleChange}
                  message={errors.pob}
                />
              </Section>
              <SelectDropdown
                options={GenderOptions}
                label="Gender *"
                name="gender"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('gender', value);
                }}
                defaultValue={values.gender}
                error={!!errors.gender && touched.gender}
                errorMessage={errors.gender}
              />
            </Section>
            <Section className="mt-10">
              <Button
                className="bg-primary text-white lg:w-56 w-full"
                type="submit"
                label="Add"
                loading={isAdding}
              />
            </Section>
          </Section>
        </Form>
      </Section>
    </Modal>
  );
};

export default AddChild;
