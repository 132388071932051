export const EducationStatusOptions = [
  { value: 'Professor', label: 'Professor' },
  { value: 'PhD', label: 'PhD' },
  { value: 'Masters Degree', label: 'Masters Degree' },
  { value: 'Postgraduate', label: 'Postgraduate' },
  { value: 'Graduate', label: 'Graduate' },
  { value: 'High School', label: 'High School' },
];

export const MaritalStatusOptions = [
  { value: 'Single', label: 'Single' },
  { value: 'Married', label: 'Married' },
  { value: 'Divorced', label: 'Divorced' },
];

export const GenderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

export const TypeOptions = [
  { value: 'Adult', label: 'Adult' },
  { value: 'Minor', label: 'Minor' },
];
