import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import React from 'react';

const CitizenshipRenunciationModule = () => {
  return (
    <Section>
      <Paragraph className="text-neutral-copy-black text-lg">
        Citizenship Renunciation
      </Paragraph>

      <div className="mt-5 p-6 text-dark font-light bg-white rounded">
        Any prospective applicant wishing to renounce his/her citizenship is
        hereby informed that the authority to approve renunciation or restore
        Nigerian citizenship is prerogative of the President of the Federal
        Republic of Nigeria. This is clearly stated in Chapter III, 1-2 of the
        1999 Constitution of the Federal Republic of Nigeria. It should be noted
        that the authority referred to in this paragraph is not transferable;
        hence all applicants should go back home and process their requests.
      </div>
    </Section>
  );
};

export default CitizenshipRenunciationModule;
