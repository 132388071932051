import Accordion from '@components/elements/Accordion';
import Section from '@components/elements/Section';
import SubHeading from '@components/elements/Text/SubHeading';
import React from 'react';

const FAQModule = () => {
  return (
    <div>
      <SubHeading text="FAQ" className="text-neutral mb-5" />

      <Section>
        <Accordion
          title="The Economy"
          content={
            <div>
              <p>Know about our Economy</p>
              <div>
                With a population of over 140 million people, Nigeria is
                obviously the largest market in sub Saharan Africa with
                reasonably skilled and potential manpower for the efficient and
                effective management of investment projects within the country.
                It is well connected by a wide network of motorable all-season
                roads, railway tracks, inland waterways, maritime and air
                transportation. Nigeria's economy could be aptly described as
                most promising. It is a mixed economy and accommodates all
                corners, individuals, corporate organisations and government
                agencies, to invest in almost all range of economic activities.
                Since 1995, the Government has introduced some bold economic
                measures, which have had a salutary effect on the economy by
                halting the declining growth in the productive sectors and
                putting a stop to galloping inflation; they have reduced the
                debt burden, stabilised the exchange rate of the Naira and
                corrected the balance of payments disequilibrium. In Successive
                budgets, since May 1999 when civil rule was restored to the
                country, Government put in place some fiscal measures, which
                addressed the exchange rate regime and the capital flight issue,
                which hitherto inhibited project planning and execution. The
                policy of expanded production through guided deregulation has
                paid off with the economy recording a real growth of over 3.2%
                of GDP. The rate of inflation declined appreciably.
              </div>
            </div>
          }
        />
      </Section>
    </div>
  );
};

export default FAQModule;
