import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import SubHeading from '@components/elements/Text/SubHeading';
import ServiceCard from '@components/widgets/service-card';
import { useAuth } from '@features/Auth/hooks/useAuthStore';
import { consular } from '@store/mocks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ConsularModule = () => {
  const navigate = useNavigate();

  const auth = useAuth();

  const isVerifed = useMemo(() => {
    if (auth.address1 && auth.phoneNumber) {
      return true;
    } else {
      return false;
    }
  }, [auth.address1, auth.phoneNumber]);

  return (
    <Section>
      <SubHeading text="Consular Services" className="text-primary mb-1" />
      <Paragraph className="font-light">
        Select the consular service you need to get started.
      </Paragraph>
      <Section className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 my-6">
        {consular.map((item, idx) => {
          return (
            <ServiceCard
              key={idx}
              icon={item.icon}
              title={item.title}
              subTitle={item.description}
              buttonText={item.buttonText}
              onClick={() => {
                if (!isVerifed) {
                  toast.warn(
                    'Kindly update your profile, Address and Phone number is required'
                  );
                  return;
                }
                navigate(item.url);
              }}
            />
          );
        })}
      </Section>
    </Section>
  );
};

export default ConsularModule;
