import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface PublicRouteProps {
  isAllowed: boolean;
  redirectPath: string;
  children: ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  isAllowed,
  redirectPath = '/landing',
  children,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children} </> : <Outlet />;
};

export default PublicRoute;
