import {
  AttestationLetterTypes,
  EtcTypes,
  NonImpedimentTypes,
  PoliceClearanceTypes,
} from '@/types/Consular';
import { pickErrorMessage, pickResult, useConfig } from '@hooks/useConfig';
import { useStepperActions } from '@hooks/useStepper';
import { useMutation } from '@tanstack/react-query';
import { handleError } from '@utils/error';
import { AxiosError } from 'axios';
import { axios } from 'lib';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export type PolicePersonalDetailsResponse = {
  police: any;
};

export type AttestationLetterResponse = {
  pensioner: any;
};

export type NonImpedimentResponse = {
  impend: any;
};

const usePoliceClearance = () => {
  const { setStep } = useStepperActions();
  const navigate = useNavigate();
  const config = useConfig();
  const {
    mutate: register,
    data,
    isLoading,
  } = useMutation<
    PolicePersonalDetailsResponse,
    AxiosError,
    Partial<PoliceClearanceTypes>
  >(
    async (data: Partial<PoliceClearanceTypes>) =>
      await axios
        .post('/police/create', data, config)
        .then((res) => {
          return res.data;
        })
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: (data) => {
        toast.success(
          'Registration Successful, Please continue to upload documents'
        );
        setStep({ step: 1, completed: true, active: false });
        navigate(
          `/consular/police-clearance/?step=upload-documents&formId=${data?.police?.id}`
        );
      },
    }
  );
  return {
    register,
    data,
    isLoading,
  };
};

const useUploadPoliceClearanceDocs = () => {
  const config = useConfig();
  const { setStep } = useStepperActions();
  const navigate = useNavigate();
  const {
    mutate: uploadDocument,
    data,
    isLoading: isFileUploading,
  } = useMutation<
    PolicePersonalDetailsResponse,
    AxiosError,
    Partial<PoliceClearanceTypes>
  >(
    async (data: Partial<PoliceClearanceTypes>) =>
      await axios
        .post('/police-clearance/file/create', data, config)
        .then((res) => pickResult(res))
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: (data) => {
        toast.success('Documents Uploaded Successfully');
        setStep({ step: 2, completed: true, active: false });
        navigate('/consular/police-clearance/?step=payment');
      },
    }
  );

  return {
    uploadDocument,
    data,
    isFileUploading,
  };
};

// attestation letter api hooks
const useAttestationLetterDocs = () => {
  const config = useConfig();
  const { setStep } = useStepperActions();
  const navigate = useNavigate();
  const {
    mutate: uploadDocument,
    data,
    isLoading: isFileUploading,
  } = useMutation<
    AttestationLetterResponse,
    AxiosError,
    Partial<AttestationLetterTypes>
  >(
    async (data: Partial<AttestationLetterTypes>) =>
      await axios
        .post('/pensioner/create', data, config)
        .then((res) => pickResult(res))
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: (data) => {
        toast.success('Documents Uploaded Successfully');
        setStep({ step: 2, completed: true, active: false });
        navigate('/consular/attestation-letter/?step=payment');
      },
    }
  );
  return {
    uploadDocument,
    data,
    isFileUploading,
  };
};

// non-impediment to marry api hooks
const useEmergencyTravelCerts = () => {
  const config = useConfig();
  const { setStep } = useStepperActions();
  const navigate = useNavigate();
  const {
    mutate: handleETCApply,
    data,
    isLoading: isApplying,
  } = useMutation<NonImpedimentResponse, AxiosError, Partial<EtcTypes>>(
    async (data: Partial<EtcTypes>) =>
      await axios
        .post('/emegency/create', data, config)
        .then((res) => pickResult(res))
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: (data) => {
        toast.success('Application Successfully');
        setStep({ step: 2, completed: true, active: false });
        navigate('/consular/emergency-travel-certificate/?step=payment');
      },
    }
  );

  return {
    handleETCApply,
    data,
    isApplying,
  };
};

const useNonImpedimentDocs = () => {
  const config = useConfig();
  const { setStep } = useStepperActions();
  const navigate = useNavigate();
  const {
    mutate: uploadDocument,
    data,
    isLoading: isFileUploading,
  } = useMutation<
    NonImpedimentResponse,
    AxiosError,
    Partial<NonImpedimentTypes>
  >(
    async (data: Partial<NonImpedimentTypes>) =>
      await axios
        .post('/impediment/create', data, config)
        .then((res) => pickResult(res))
        .catch((err) => pickErrorMessage(err)),
    {
      onError: async (error: any) => {
        toast.error(handleError(error));
      },
      onSuccess: (data) => {
        toast.success('Documents Uploaded Successfully');
        setStep({ step: 2, completed: true, active: false });
        navigate('/consular/non-impediment/?step=payment');
      },
    }
  );

  return {
    uploadDocument,
    data,
    isFileUploading,
  };
};

export {
  usePoliceClearance,
  useUploadPoliceClearanceDocs,
  useAttestationLetterDocs,
  useNonImpedimentDocs,
  useEmergencyTravelCerts,
};
