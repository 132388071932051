import * as Yup from 'yup';

export const personalDetailsSchema = Yup.object().shape({
  fullName: Yup.string().required('Your full name is required'),
  maidenName: Yup.string(),
  address: Yup.string().required('Address is required'),
  telNo: Yup.string().required('Phone number is required').min(10, 'Phone number is invalid'),
  country: Yup.string().required('Country is required'),
  nationality: Yup.string().required('Nationality is required'),
  dob: Yup.date().required('Date of birth is required'),
  placeOfBirth: Yup.string().required('Place of birth is required'),
  educationStatus: Yup.string().required('Education status is required'),
  maritalStatus: Yup.string().required('Marital status is required'),
  passportNumber: Yup.string().required('Passport number is required'),
  addressInNig: Yup.string().required('Address in Nigeria is required'),
  dateLastResidenceInNig: Yup.date().required('Date is required'),
  presentEmployment: Yup.string().required('Present employment is required'),
  fatherName: Yup.string().required("Father's name is required"),
  fatherAddress: Yup.string().required("Father's address is required"),
  fatherOcupation: Yup.string().required("Father's occupation is required"),
  dateFilled: Yup.date().required("Please pick a date"),
});

export const uploadDocumentsSchema = Yup.object().shape({
  passportPhotocopy: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  visaPhotocopy: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  passportPhotograph: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  dataFormCopy: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  signature: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
});

export const clearancePaymentSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  cardNumber: Yup.string().required('Card number is required'),
  expirationDate: Yup.string().required('Date is required'),
  securityCode: Yup.string().required('Please enter security code'),
  zipCode: Yup.string().required("Zip code is required"),
});