import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { setStudentRegPayload, studentRegPayload } from '../Student.slice';
import { Student } from '@/types/Student';

export const useStudentRegActions = () => {
  const dispatch = useAppDispatch();
  return {
    setStudentRegPayload: useCallback(
      (studentRegPayload: Student) =>
        dispatch(setStudentRegPayload(studentRegPayload)),
      [dispatch]
    ),
  };
};

export const useStudentRegPayload = () => useAppSelector(studentRegPayload);
