import * as Yup from 'yup';

export const uploadDocumentsSchema = Yup.object().shape({
  proofOfDivorce: Yup.mixed()
    .nullable()
    .required('Please upload proof of divorce')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  passportPhotocopy: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  notorizedDeclaration: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  proofOfMarriage: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
});

export const nonImpedimentPaymentSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  cardNumber: Yup.string().required('Card number is required'),
  expirationDate: Yup.string().required('Date is required'),
  securityCode: Yup.string().required('Please enter security code'),
  zipCode: Yup.string().required("Zip code is required"),
});