import Card from '@components/elements/Card';
import Heading from '@components/elements/Text/Heading';
import TextField from '@components/elements/TextField';
import Section from '@components/elements/Section';
import Button from '@components/elements/Button';
import Paragraph from '@components/elements/Text/Paragraph';
import { Link, useNavigate } from 'react-router-dom';
import Image from '@components/elements/Image';
import { eyeIcon, logo } from '@assets/icons';
import { useFormik } from 'formik';
import { useSignInMutation, SignInCredentials } from '@features/Auth/api';
import Form from '@components/elements/Form';
import { signInValidationSchema } from '@utils/validationSchema';
import { toast } from 'react-toastify';
import { useAuthActions } from '@features/Auth/hooks/useAuthStore';
import { useState } from 'react';
import { axios } from 'lib';

const SigninForm = () => {
  const { mutate, isLoading } = useSignInMutation();
  const navigate = useNavigate();
  const { setUser } = useAuthActions();
  const [showPassword, setShowPassword] = useState(false);

  const verifyEmail = async (endpoint: string, token: string) => {
    try {
      const res = await axios.post(endpoint, '', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.info('Email Verification Link Sent, Kindly check your email');
      }
    } catch (error) {
      toast.error('Oops!! Something went wrong, Try again!!');
    }
  };

  const { handleSubmit, values, handleChange, errors, touched } =
    useFormik<SignInCredentials>({
      initialValues: { email: '', password: '' },
      validationSchema: signInValidationSchema,
      onSubmit: async (values: SignInCredentials): Promise<void> => {
        mutate(values, {
          onSuccess: (data) => {
            toast.success('Login Successful');
            const userData = data.user;
            const payload = {
              token: data?.token,
              ...userData,
            };
            setUser(payload);
            navigate('/');
          },
          onError: (error) => {
            const message = (error?.response?.data as any).message;
            const endpoint = (error?.response?.data as any).resendVerification;
            const token = (error?.response?.data as any).token;
            toast.error(message);
            if (message === 'Kindly Verify Your Email') {
              verifyEmail(endpoint, token)
                .then((res) => {
                  return res;
                })
                .catch((error) => console.log(error));
            }
          },
        });
      },
    });

  return (
    <Card className="bg-white rounded-3xl w-full lg:w-[532px]">
      <Form onSubmit={handleSubmit}>
        <Section className="flex items-center justify-center">
          <Image src={logo} alt="Nigerian High Commission London UK" />
        </Section>
        <Section>
          <Heading className={'text-primary my-7 text-center'}>
            Login to continue
          </Heading>
          <Section>
            <TextField
              placeholder="Enter Email"
              name="email"
              label="Email"
              value={values.email}
              onChange={handleChange}
              error={!!errors.email && touched.email}
              message={errors.email}
            />
          </Section>
          <Section className="my-6">
            <TextField
              placeholder="*********"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={values.password}
              onChange={handleChange}
              error={!!errors.password && touched.password}
              message={errors.password}
              icon={
                <img
                  onClick={() => setShowPassword(!showPassword)}
                  src={eyeIcon}
                  height={24}
                  width={24}
                  alt="password icon"
                />
              }
            />
          </Section>
          <Paragraph className="underline font-light mb-6 text-neutral-copy-black">
            Forgot your password?
          </Paragraph>
          <Section className="mb-6">
            <Button
              type="submit"
              className="bg-primary text-white"
              label="Login"
              loading={isLoading}
            />
          </Section>
          <Paragraph className="text-center text-neutral-copy-black">
            Your first time here, please{' '}
            <Link to="/signup" className="text-primary">
              Create account
            </Link>
          </Paragraph>
        </Section>
      </Form>
    </Card>
  );
};

export default SigninForm;
