import Badge from '@components/elements/Badge'
import Section from '@components/elements/Section'


interface AppStatusProps {
    title: string;
    status: string;
}
interface StatusTextProps {
    [key: string]: string
}

const statusText: StatusTextProps = {
    success: "Approved",
    warn: "Pending",
    danger: "Not Approved",
    default: "Resume"
}

const AppStatus: React.FC<AppStatusProps> = ({ title, status }) => {
    return (
        <Section className='flex items-center justify-between border rounded-2xl border-[#EBEBEB] px-4 py-5 mb-4'>
            <Section>
                {title || "Student Registration"}
            </Section>
            <Section>
                <Badge text={statusText[status]} status={status} />
            </Section>
        </Section>
    )
}

export default AppStatus