import React, { useState } from 'react';
import Button from '@components/elements/Button';
import TextField from '@components/elements/TextField';
import SidebarMenuItem from '@components/elements/Menu/SidebarMenuItem';
import {
  helpActive,
  helpBase,
  profileIcon,
  serviceActive,
  serviceBase,
  userActive,
} from '@assets/icons';
import { useLocation } from 'react-router-dom';
import SelectDropdown from '@components/elements/Select';
import TextArea from '@components/elements/TextArea';
import Stepper from '@components/widgets/stepper/Stepper';
import { useStepperActions } from '@hooks/useStepper';
import AboutUniversity from '@features/Student/components/AboutUniversity';
import StudentsDocumentUpload from '@features/Student/components/StudentsDocumentUpload';
import Terms from '@features/Student/components/Terms';
import EditProfile from '@components/widgets/profile';
import ServiceCard from '@components/widgets/service-card';
import Section from '@components/elements/Section';
import SubHeading from '@components/elements/Text/SubHeading';
import Paragraph from '@components/elements/Text/Paragraph';
import { consular } from '@store/mocks';
import AppStatus from '@components/widgets/app-status';

const Test = () => {
  const location = useLocation();
  const { setStep } = useStepperActions();
  const [url, setUrl] = useState();
  return (
    <div className="bg-white p-8">
      <Button className="bg-primary" />
      <Button className="bg-tertiary" />
      <Button className="bg-warning border-primary" />
      <Button className="bg-secondary" />
      <Button className="bg-primary" loading />
      <Button className="bg-tertiary border-primary border" />
      <Button className="bg-warning border-primary" />
      <Button className="bg-secondary" />
      <TextField label="Email" type="email" error />
      <SidebarMenuItem
        title="Profile"
        active={location.pathname === '/test'}
        url="/test"
        activeIcon={userActive}
        baseIcon={profileIcon}
      />
      <SidebarMenuItem
        title="Services"
        active={location.pathname !== '/test'}
        url="/test"
        activeIcon={serviceActive}
        baseIcon={serviceBase}
      />
      <SidebarMenuItem
        title="FAQ"
        active={location.pathname !== '/test'}
        url="/test"
        activeIcon={helpActive}
        baseIcon={helpBase}
      />
      {/* <SidebarMenuItem
        title="Contact Us"
        active={location.pathname !== '/test'}
        url="/test"
        activeIcon={userActive}
        baseIcon={profileIcon}
      /> */}
      <SelectDropdown
        error
        options={[]}
        label="Check for group County Location"
      />
      <TextArea label={'Sample label'} error />

      <Stepper />

      <Button
        onClick={() => {
          setStep({ step: 1, completed: true, active: true });
        }}
      />

      <AboutUniversity />
      <StudentsDocumentUpload />
      <Terms />
      <EditProfile />
      <SubHeading text="Consular Services" className="text-primary mb-1" />
      <Paragraph className="font-light">
        Select the consular service you need to get started.
      </Paragraph>
      <Section className="grid grid-cols-3 gap-4 my-6">
        {consular.map((item, idx) => {
          return (
            <ServiceCard
              key={idx}
              icon={item.icon}
              title={item.title}
              subTitle={item.description}
              buttonText={item.buttonText}
            />
          );
        })}
      </Section>
      {/* </Modal> */}

      <AppStatus title={'Student Registration'} status={'default'} />
      <AppStatus title={'Police Clearance'} status={'success'} />
      <AppStatus title={'Citizenship Renunciation'} status={'warn'} />
      <AppStatus title={'Non-Impediment to Marry'} status={'danger'} />
      <div className="mb-40"></div>
    </div>
  );
};

export default Test;
