import { NonImpedimentTypes } from '@/types/Consular';
import Button from '@components/elements/Button';
import Card from '@components/elements/Card';
import Form from '@components/elements/Form';
import FormContainer from '@components/elements/FormContainer';
import Section from '@components/elements/Section';
import Heading from '@components/elements/Text/Heading';
import TextField from '@components/elements/TextField';
import { useConsularActions } from '@features/Consular/hooks/useConsularActions';
import { useStepperActions } from '@hooks/useStepper';
import { nonImpedimentPaymentSchema } from '@utils/validationSchema/nonImpedimentSchema';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NonImpedimentPayment = () => {
  const { setNonImpedimentPayload } = useConsularActions();
  const { setStep } = useStepperActions();
  const navigate = useNavigate();

  const intitalValues: Partial<NonImpedimentTypes> = {
    firstName: '',
    lastName: '',
    cardNumber: '',
    expirationDate: '' as unknown as Date,
    securityCode: '',
    zipCode: '',
  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik<
    Partial<NonImpedimentTypes>
  >({
    initialValues: intitalValues,
    validationSchema: nonImpedimentPaymentSchema,
    onSubmit: async (values: Partial<NonImpedimentTypes>) => {
      setNonImpedimentPayload(values as NonImpedimentTypes);
      setStep({ step: 2, completed: true, active: false });
      navigate('/consular');
    },
  });

  const prev = () => {
    navigate('/consular/non-impediment/?step=upload-documents');
  };

  const pricing = [
    { title: 'Service charge', amount: '£20.00' },
    { title: 'Processing fee', amount: '£20.00' },
    { title: 'Tax', amount: '£5.00' },
  ];

  return (
    <div className="pb-10">
      <Card className="bg-white">
        <Form onSubmit={handleSubmit}>
          <Heading className={'text-primary mb-8'}>Fill card details</Heading>

          <Section>Images</Section>

          <Section className="grid grid-cols-1 gap-y-8">
            <FormContainer>
              <TextField
                placeholder="First name"
                name="firstName"
                value={values.firstName}
                error={!!errors?.firstName && touched?.firstName}
                onChange={handleChange}
                message={errors.firstName}
              />

              <TextField
                placeholder="Last name"
                name="lastName"
                value={values.lastName}
                error={!!errors?.lastName && touched?.lastName}
                onChange={handleChange}
                message={errors.lastName}
              />

              <TextField
                placeholder="Card number"
                name="cardNumber"
                value={values.cardNumber}
                error={!!errors?.cardNumber && touched?.cardNumber}
                onChange={handleChange}
                message={errors.cardNumber}
              />

              <TextField
                placeholder="Expiration date (MM/YY)"
                type="date"
                name="expirationDate"
                value={values.expirationDate}
                error={!!errors?.expirationDate && touched?.expirationDate}
                onChange={handleChange}
                message={errors.expirationDate}
              />

              <TextField
                placeholder="Security code"
                name="securityCode"
                value={values.securityCode}
                error={!!errors?.securityCode && touched?.securityCode}
                onChange={handleChange}
                message={errors.securityCode}
              />

              <TextField
                placeholder="Card number"
                name="zipCode"
                value={values.zipCode}
                error={!!errors?.zipCode && touched?.zipCode}
                onChange={handleChange}
                message={errors.zipCode}
              />
            </FormContainer>

            <div className="text-neutral py-6 px-8 rounded bg-primary/10">
              {pricing?.map((list, i) => (
                <div key={i} className="mb-5 flex justify-between">
                  <span className="font-light">{list?.title}</span>
                  <span className="font-medium">{list?.amount}</span>
                </div>
              ))}
              <div className="pt-3 flex justify-between border-t border-primary">
                <span className="font-semibold">Total</span>
                <span className="font-medium">£45.00</span>
              </div>
            </div>
          </Section>

          <Section className="flex items-center mt-10">
            <Button
              onClick={prev}
              label="Previous"
              className="border border-neutral-500 lg:w-56 hover:text-dark"
            />

            <Button
              className="bg-primary text-white lg:w-56 w-full ml-5"
              type="submit"
              label="Confirm Payment"
            />
          </Section>
        </Form>
      </Card>
    </div>
  );
};

export default NonImpedimentPayment;
