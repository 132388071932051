import { checkHc, logo } from '@assets/icons';
import Image from '@components/elements/Image';
import ListGroup from '@components/elements/ListGroup';
import ListItem from '@components/elements/Listitem';
import Section from '@components/elements/Section';
import Heading from '@components/elements/Text/Heading';
import Paragraph from '@components/elements/Text/Paragraph';
import SubHeading from '@components/elements/Text/SubHeading';
import appRoutes from '@components/router/appRoutes';
import { consular } from '@store/mocks';

const Services = () => {
  return (
    <Section className="w-full bg-white">
      <Section className="px-16 py-16 flex flex-col justify-between">
        <Section className="mb-10">
          <a href={appRoutes.WEBSITE} target="_blank" rel="noreferrer">
            <Image src={logo} alt="Nigerian High Commssion London UK" />
          </a>
        </Section>
        <Heading className={'text-neutral-copy-black mb-6'}>
          Diaspora Portal
        </Heading>
        <Section className="xl:mb-[48px]">
          <ListGroup className="list-image-[url(src/assets/icons/check-hc.svg)]">
            {consular.map((item, idx) => (
              <ListItem
                className={idx === consular.length - 1 ? 'mb-0' : 'mb-6'}
                key={item.id}
              >
                <Section className="flex items-start">
                  <Section className="flex items-center p-1">
                    <Image
                      src={checkHc}
                      alt="Nigerian High Commission London UK"
                    />
                  </Section>
                  <Section className="ml-2 flex-1">
                    <SubHeading
                      text={item.title}
                      className="text-neutral-copy-black"
                    />
                    <Paragraph className="text-neutral-copy-black text-sm">
                      {item.description}
                    </Paragraph>
                  </Section>
                </Section>
              </ListItem>
            ))}
          </ListGroup>
        </Section>

        <Paragraph className="xl:pt-5 xl:w-96 bg-white text-neutral-copy-black lg:static xl:absolute bottom-5">
          Want to see more info,{' '}
          <a href={appRoutes.WEBSITE} className="text-primary">
            visit our website
          </a>
        </Paragraph>
      </Section>
    </Section>
  );
};

export default Services;
