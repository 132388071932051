import * as Yup from 'yup';

export const personalDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Your Title is required'),
  fullname: Yup.string().required('Your full name is required'),
  surname: Yup.string().required('Your surname is required'),
  otherName: Yup.string(),
  type: Yup.string().required('Type is required, select either Minor or Adult'),
  dob: Yup.date().required('Date of birth is required'),
  pob: Yup.string().required('Place of birth is required'),
  stateOfOrigin: Yup.string().required('State of Origin is required'),
  lga: Yup.string().required('LGA of Origin is required'),
  addressInNigeria: Yup.string().required('Address in Nigeria is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .min(10, 'Phone number is invalid'),
  country: Yup.string().required('Country is required'),
  nationality: Yup.string().required('Nationality is required'),
  permanentAddress: Yup.string().required('Permanent Address is required'),
  maritalStatus: Yup.string().required('Marital status is required'),
  occupation: Yup.string().required('Present Employment is required'),
  passportNo: Yup.string().required('Passport number is required'),
  gender: Yup.string().required('Gender is required'),
  dateOfIssue: Yup.date().required('Date of passport issue is required'),
  placeOfIssue: Yup.string().required('Place of passport isssue is required'),
  reasonInUk: Yup.string().required("Describe the reason why you're in UK"),
  postcode: Yup.string().required('Postcode is required'),
  whereYouReside: Yup.string().required(
    'Where you live currently is required '
  ),
  reasonForRequest: Yup.string().required(
    "Describe the reason why you're requesting for ETC"
  ),
  dateLastResidenceInNig: Yup.date().required(
    'Date of last resisdence is required'
  ),
});

export const nextOfKinSchema = Yup.object().shape({
  kinSurname: Yup.string().required('Next of Kin Surname is required'),
  kinOthername: Yup.string().required('Next of Kin othername is required'),
  kinGender: Yup.string().required('Next of Kin Gender required'),
  kinAddress: Yup.string().required('Next of Kin Address is required'),
  kinStateOfOrigin: Yup.string().required(
    'Next of Kin State of Origin is required'
  ),
  kinLga: Yup.string().required('Next of Kin LGA is required'),
  kinEmail: Yup.string()
    .email('Invalid email format')
    .required('Next of Kin email is required'),
  kinOccupation: Yup.string().required('Next of Kin Occupation is required'),
  kinCountry: Yup.string().required('Next of Kin Country is required'),
  kinRelationship: Yup.string().required(
    'next of Kin relationship is required'
  ),
  kinrefreeName: Yup.string().required('Next of Kin Referee is required'),
  refreeCity: Yup.string().required('Referee City is required'),
  refreePostcode: Yup.string().required('Referee Post Code is required'),
  dateFilled: Yup.date().required('Please pick a date'),
});
