import Section from '@components/elements/Section';
import SignupForm from './form';
import Services from '../signin/services';

const Signup = () => {
  return (
    <Section className="grid lg:grid-cols-3 grid-cols-1">
      <Section className="col-span-1 hidden lg:block lg:h-screen overflow-y-auto scrollbar-hide bg-white">
        <Services />
      </Section>
      <Section className="lg:col-span-2 h-full lg:h-screen flex items-center justify-center">
        <SignupForm />
      </Section>
    </Section>
  );
};

export default Signup;

// create endpoint for
