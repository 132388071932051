import { useMemo } from 'react';
import Card from '@components/elements/Card';
import Heading from '@components/elements/Text/Heading';
import TextField from '@components/elements/TextField';
import SelectDropdown from '@components/elements/Select';
import FormContainer from '@components/elements/FormContainer';
import Button from '@components/elements/Button';
import Section from '@components/elements/Section';
import { Student } from '@/types/Student';
import { personalInfoSchema } from '@utils/validationSchema';
import { useFormik } from 'formik';
import TextArea from '@components/elements/TextArea';
import Form from '@components/elements/Form';
import { useStudentRegActions } from '../hooks/useStudentRegActions';
import { gender, getStates } from '@store/mocks';
import { useStepperActions } from '@hooks/useStepper';
import { useNavigate } from 'react-router-dom';

const PersonalInfo = () => {
  const intitalValues: Partial<Student> = {
    firstName: '',
    lastName: '',
    date_of_birth: '' as unknown as Date,
    gender: '' as unknown as 'male',
    state: '',
    passport_number: '',
    phone: '',
    address_one: '',
    address_two: '',
    city: '',
    postcode: '',
    ng_address: '',
  };

  const { setStudentRegPayload } = useStudentRegActions();
  const { setStep } = useStepperActions();
  const navigate = useNavigate();
  const states = useMemo(() => getStates(), []);

  const { handleSubmit, values, handleChange, errors, touched, setFieldValue } =
    useFormik<Partial<Student>>({
      initialValues: intitalValues,
      validationSchema: personalInfoSchema,
      onSubmit: async (values: Partial<Student>) => {
        setStudentRegPayload(values as Student);
        setStep({ step: 1, completed: true, active: false });
        navigate('/students-reg/?step=about-university');
      },
    });

  return (
    <Card className="bg-white">
      <Form onSubmit={handleSubmit}>
        <Section>
          <Heading className={'text-primary mb-8'}>
            Personal Information
          </Heading>
          <Section className="grid grid-3">
            <FormContainer>
              <TextField
                placeholder="Enter firstname"
                label="First Name"
                name="firstName"
                value={values.firstName}
                error={!!errors?.firstName && touched?.firstName}
                onChange={handleChange}
                message={errors.firstName}
              />
              <TextField
                placeholder="Enter LastName"
                label="Last Name"
                name="lastName"
                value={values.lastName}
                error={!!errors?.lastName && touched?.lastName}
                onChange={handleChange}
                message={errors.lastName}
              />
            </FormContainer>
            <FormContainer>
              <TextField
                placeholder="Enter date of birth"
                label="Date of Birth"
                type="date"
                name="date_of_birth"
                value={values.date_of_birth}
                error={!!errors?.date_of_birth && touched?.date_of_birth}
                onChange={handleChange}
                message={errors.date_of_birth}
              />
              <SelectDropdown
                options={[...gender]}
                // onChange={handleChange}
                label="Gender"
                name="gender"
                defaultValue={values.gender}
                error={!!errors.gender && touched.gender}
                errorMessage={errors.gender}
                onChange={({ value }: { value: string }) => {
                  setFieldValue('gender', value);
                }}
              />
            </FormContainer>
            <FormContainer>
              <TextField
                placeholder="E.g Abbey Wood"
                label="City"
                type="text"
                name="city"
                value={values.city}
                error={!!errors?.city && touched?.city}
                onChange={handleChange}
                message={errors.city}
              />
              <TextField
                placeholder="Enter Passport Number"
                label="Passport Number"
                type="text"
                name="passport_number"
                value={values.passport_number}
                error={!!errors?.passport_number && touched?.passport_number}
                onChange={handleChange}
                message={errors.passport_number}
              />
            </FormContainer>
            <FormContainer>
              <SelectDropdown
                options={[...states]}
                label="State of Origin"
                name="state"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('state', value);
                }}
                defaultValue={values.state}
                error={!!errors.state && touched.state}
                errorMessage={errors.state}
              />
              <TextField
                placeholder="+234XXXXXXXXX"
                label="Phone Number"
                type="text"
                name="phone"
                value={values.phone}
                error={!!errors.phone && touched.phone}
                message={errors.phone}
                onChange={handleChange}
              />
            </FormContainer>
            <FormContainer>
              <TextField
                placeholder="E.g No 1 Igboefon ...."
                label="Address Line 1"
                type="text"
                name="address_one"
                value={values.address_one}
                error={!!errors?.address_one && touched?.address_one}
                onChange={handleChange}
                message={errors.address_one}
              />
              <TextField
                placeholder="E.g No 2 Igboefon ...."
                label="Address Line 2"
                type="text"
                name="address_two"
                value={values.address_two}
                error={!!errors?.address_two && touched?.address_two}
                onChange={handleChange}
                message={errors.address_two}
              />
            </FormContainer>

            <TextArea
              placeholder="Enter Your Nigerian Address"
              label="Current Address in Nigeria for those Student on Scholarship"
              name="ng_address"
              textAreaName="ng_address"
              value={values.ng_address}
              error={!!errors?.ng_address && touched?.ng_address}
              onChange={handleChange}
            />
            <Button
              className="bg-primary text-white lg:w-64 w-full mt-4"
              type="submit"
              label="Next"
              // This will handle the validation errors returned by the validatePersonalInfo function and allow you to display them to the user.
            />
          </Section>
        </Section>
      </Form>
    </Card>
  );
};

export default PersonalInfo;
