import { PoliceClearanceTypes } from '@/types/Consular';
import Card from '@components/elements/Card';
import Form from '@components/elements/Form';
import Heading from '@components/elements/Text/Heading';
import { uploadDocumentsSchema } from '@utils/validationSchema/policeClearanceSchema';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Section from '@components/elements/Section';
import Uploader from '@components/elements/Uploader';
import Button from '@components/elements/Button';
import Paragraph from '@components/elements/Text/Paragraph';
import { useAuth } from '@features/Auth/hooks/useAuthStore';
import { useUploadPoliceClearanceDocs } from '@features/Consular/api';

const UploadDocuments = () => {
  const { id } = useAuth();
  const [search] = useSearchParams();

  const formId = search.get('formId');
  const { uploadDocument, isFileUploading } = useUploadPoliceClearanceDocs();
  const navigate = useNavigate();

  const [signature, setSignature] = useState<string | null>(null);
  const [passportPhotocopy, setPassportPhotocopy] = useState<string | null>(
    null
  );
  const [visaPhotocopy, setVisaPhotocopy] = useState<string | null>(null);
  const [passportPhotograph, setPassportPhotograph] = useState<string | null>(
    null
  );
  const [dataFormCopy, setDataFormCopy] = useState<string | null>(null);

  const intitalValues: Partial<PoliceClearanceTypes> = {
    passportPhotocopy: passportPhotocopy as unknown as Blob,
    visaPhotocopy: visaPhotocopy as unknown as Blob,
    passportPhotograph: passportPhotograph as unknown as Blob,
    dataFormCopy: dataFormCopy as unknown as Blob,
    signature: signature as unknown as Blob,
  };

  const { handleSubmit, values, errors, setFieldValue, setFieldError } =
    useFormik<Partial<PoliceClearanceTypes>>({
      initialValues: intitalValues,
      validationSchema: uploadDocumentsSchema,
      onSubmit: async (values: Partial<PoliceClearanceTypes>) => {
        let formData = new FormData();

        formData.set('passportPhotocopy', values?.passportPhotocopy as Blob);
        formData.set('visaPhotocopy', values.visaPhotocopy as Blob);
        formData.set('passportPhotograph', values.passportPhotograph as Blob);
        formData.set('dataFormCopy', values.dataFormCopy as Blob);
        formData.append('signature', values.signature as Blob);
        formData.set('child_form_id', formId as unknown as string);
        formData.set('user_id', id as unknown as string);

        uploadDocument(formData as any);
      },
    });

  const prev = () => {
    navigate('/consular/police-clearance');
  };

  return (
    <div className="pb-10">
      <Card className="bg-white">
        <Form onSubmit={handleSubmit}>
          <Heading className={'text-primary mb-8'}>
            Upload Documents for Police Criminal Check (From Nigeria)
          </Heading>

          <Section className="grid grid-cols-1 gap-y-8">
            <Paragraph className="text-dark border border-[#EDF1EB] py-4 px-6 font-light">
              In order to process your application, we will require that you
              present to us either by post through registered mail and
              self-addressed registered envelope or in person.
            </Paragraph>

            <Uploader
              url={values.passportPhotocopy}
              label="Photocopy of Nigeria Passport *"
              setUrl={setPassportPhotocopy}
              name="passportPhotocopy"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.passportPhotocopy}
              error={!!errors.passportPhotocopy}
            />

            <Uploader
              url={values.visaPhotocopy}
              label="Photocopy of current visa *"
              setUrl={setVisaPhotocopy}
              name="visaPhotocopy"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.visaPhotocopy}
              error={!!errors.visaPhotocopy}
            />

            <Uploader
              url={values.passportPhotograph}
              label="Passport photograph *"
              setUrl={setPassportPhotograph}
              name="passportPhotograph"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.passportPhotograph}
              error={!!errors.passportPhotograph}
            />

            <Uploader
              url={values.dataFormCopy}
              label="A copy of data form (filled) *"
              setUrl={setDataFormCopy}
              name="dataFormCopy"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.dataFormCopy}
              error={!!errors.dataFormCopy}
            />
          </Section>
          <Section className="my-6">
            <Uploader
              url={values.signature}
              label="Upload Signature *"
              setUrl={setSignature}
              name="signature"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.signature}
              error={!!errors.signature}
            />
          </Section>
          <Section className="flex items-center mt-10">
            <Button
              onClick={prev}
              label="Previous"
              className="border border-neutral-500 lg:w-56 hover:text-dark"
            />
            <Button
              className="bg-primary text-white lg:w-56 w-full ml-5"
              type="submit"
              label="Next"
              loading={isFileUploading}
            />
          </Section>
        </Form>
      </Card>
    </div>
  );
};

export default UploadDocuments;
