import { PaymentPayload } from '@/types/Payment';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/types';

export interface PaymentState {
  paymentPayload: PaymentPayload | null;
}

const initialState: PaymentState = {
  paymentPayload: null,
};

export const PaymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentPayload: (
      state: PaymentState,
      { payload }: PayloadAction<PaymentPayload>
    ) => {
      state.paymentPayload = { ...state.paymentPayload, ...payload };
    },
  },
});

export const { setPaymentPayload } = PaymentSlice.actions;
export const paymentPayload = (state: RootState) =>
  state.payment.paymentPayload;
export default PaymentSlice;
