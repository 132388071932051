import React from 'react';
import HomePage from '@pages/index';
import Test from '@pages/test';
import { Route, Routes } from 'react-router-dom';
import appRoutes from './appRoutes';
import Signup from '@pages/signup';
import SignIn from '@pages/signin';
import StudentsReg from '@pages/student-reg';
import ProtectedRoute from './protectedRoute';
import { useAuth } from '@features/Auth/hooks/useAuthStore';
import PublicRoute from './publicRoute';
import PoliceClearance from '@pages/police-clearance';
import CitizenshipRenunciation from '@pages/citizenship-renunciation';
import AttestationLetter from '@pages/attestation-letter';
import NonImpediment from '@pages/non-impediment';
import FAQ from '@pages/faq';
import Contact from '@pages/contact';
import AdminLogin from '@pages/admin-login';
import DocumentAuth from '@pages/document-auth';
import EmergencyTravelCerts from '@pages/emergency-travel';
import Profile from '@pages/profile';
import Payment from '@pages/payment';
import Applications from '@pages/applications';

const AppRoutes: React.FC<{}> = () => {
  const auth = useAuth();

  return (
    <Routes>
      <Route
        path={appRoutes.HOME}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <HomePage />
          </ProtectedRoute>
        }
      ></Route>
      <Route path={appRoutes.TEST} element={<Test />}></Route>
      <Route
        path={appRoutes.SIGNUP}
        element={
          <PublicRoute isAllowed={auth === null} redirectPath={'/'}>
            <Signup />
          </PublicRoute>
        }
      ></Route>
      <Route
        path={appRoutes.SIGNIN}
        element={
          <PublicRoute isAllowed={auth === null} redirectPath={'/'}>
            <SignIn />
          </PublicRoute>
        }
      ></Route>
      <Route
        path={appRoutes.STUDENT_REG}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <StudentsReg />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.POLICE_CLEARANCE}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <PoliceClearance />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.CITIZENSHIP_RENUNCIATION}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <CitizenshipRenunciation />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.ATTESTATION_LETTER}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <AttestationLetter />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.NON_IMPEDIMENT}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <NonImpediment />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.FAQ}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <FAQ />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.ETC}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <EmergencyTravelCerts />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.CONTACT}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Contact />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.DOCUMENT_AUTH}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <DocumentAuth />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.PROFILE}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Profile />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.PAYMENT}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Payment />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path={appRoutes.APPLICATIONS}
        element={
          <ProtectedRoute isAllowed={auth !== null} redirectPath={'/signin'}>
            <Applications />
          </ProtectedRoute>
        }
      ></Route>
    </Routes>
  );
};

export default AppRoutes;
