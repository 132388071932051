import {
  close,
  helpActive,
  helpBase,
  logo,
  logout,
  profileIcon,
  serviceActive,
  serviceBase,
  userActive,
} from '@assets/icons';
import Image from '@components/elements/Image';
import SidebarMenuItem from '@components/elements/Menu/SidebarMenuItem';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import appRoutes from '@components/router/appRoutes';
import { useAuthActions } from '@features/Auth/hooks/useAuthStore';

import { useLocation, useNavigate } from 'react-router-dom';

interface MobileSideNavProps {
  openMobileNav: boolean;
  setOpenMobileNav: () => void;
}

const MobileSideNav: React.FC<MobileSideNavProps> = ({
  openMobileNav,
  setOpenMobileNav,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuthActions();

  return (
    <>
      <div
        className={`z-50 flex flex-col justify-between fixed top-0 bottom-0 h-full bg-white px-4 py-8 w-80 sm:w-96 transition-all ease-in-out duration-300 overflow-y-auto ${
          openMobileNav ? 'left-0' : '-left-[32rem]'
        }`}
      >
        <Section>
          <button onClick={setOpenMobileNav} className="block w-fit ml-auto">
            <img src={close} alt="icon" width={20} height={20} />
          </button>

          <div>
            <img src={logo} alt="light logo" />
          </div>

          <aside className="mt-10">
            <div className="mb-2">
              <SidebarMenuItem
                onClick={setOpenMobileNav}
                title="Consular"
                active={
                  location.pathname === '/' ||
                  location.pathname.includes(appRoutes.CONSULAR_SERVICES) ||
                  location.pathname.includes(appRoutes.STUDENT_REG)
                }
                url="/"
                activeIcon={serviceActive}
                baseIcon={serviceBase}
              />
            </div>

            <div className="mb-2">
              <SidebarMenuItem
                onClick={setOpenMobileNav}
                title="FAQ"
                active={location.pathname === '/faq'}
                url="/faq"
                activeIcon={helpActive}
                baseIcon={helpBase}
              />
            </div>

            {/* <div className="mb-2">
              <SidebarMenuItem
                onClick={setOpenMobileNav}
                title="Contact Us"
                active={location.pathname === '/contact'}
                url="/contact"
                activeIcon={userActive}
                baseIcon={profileIcon}
              />
            </div> */}
          </aside>
        </Section>

        <Section
          className="mt-auto flex items-center cursor-pointer"
          onClick={() => {
            setUser(null);
            sessionStorage.clear();
            setOpenMobileNav();
            return navigate('/signin');
          }}
        >
          <Image
            src={logout}
            alt="logout | Nigerian High Commission London UK"
          />{' '}
          <Paragraph className="ml-2">Logout</Paragraph>
        </Section>
      </div>

      {/* overlay */}
      {openMobileNav && (
        <div
          onClick={setOpenMobileNav}
          className="h-full w-full z-20 bg-dark/50 fixed top-0 left-0 bottom-0"
        ></div>
      )}
    </>
  );
};

export default MobileSideNav;
