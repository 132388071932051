import * as Yup from 'yup';

export const attestationLetterSchema = Yup.object().shape({
  retirementCertificateCopy: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  passportPhotocopy: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  passportPhotograph: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
  englandAddressProof: Yup.mixed()
    .nullable()
    .required('')
    .test(
      'FILE_SIZE',
      'File is too big, Reduce the file and re-upload',
      (value) => !value || (value && (value as File).size <= 1024 * 1024)
    ),
});
