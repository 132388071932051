import { AttestationLetterTypes } from '@/types/Consular';
import Button from '@components/elements/Button';
import Card from '@components/elements/Card';
import Form from '@components/elements/Form';
import Section from '@components/elements/Section';
import Heading from '@components/elements/Text/Heading';
import Paragraph from '@components/elements/Text/Paragraph';
import Uploader from '@components/elements/Uploader';
import { useAuth } from '@features/Auth/hooks/useAuthStore';
import { useAttestationLetterDocs } from '@features/Consular/api';
import { attestationLetterSchema } from '@utils/validationSchema/attestationLetterSchema';
import { useFormik } from 'formik';
import { useState } from 'react';

const AttestationLetterModule = () => {
  const { id } = useAuth();
  const { uploadDocument, isFileUploading } = useAttestationLetterDocs();

  const [retirementCertificateCopy, setRetirementCertificateCopy] = useState<
    string | null
  >(null);
  const [passportPhotocopy, setPassportPhotocopy] = useState<string | null>(
    null
  );
  const [passportPhotograph, setPassportPhotograph] = useState<string | null>(
    null
  );
  const [englandAddressProof, setEnglandAddressProof] = useState<string | null>(
    null
  );

  const intitalValues: Partial<AttestationLetterTypes> = {
    retirementCertificateCopy: retirementCertificateCopy as unknown as Blob,
    passportPhotocopy: passportPhotocopy as unknown as Blob,
    passportPhotograph: passportPhotograph as unknown as Blob,
    englandAddressProof: englandAddressProof as unknown as Blob,
  };

  const { handleSubmit, values, errors, setFieldValue, setFieldError } =
    useFormik<Partial<AttestationLetterTypes>>({
      initialValues: intitalValues,
      validationSchema: attestationLetterSchema,
      onSubmit: async (values: Partial<AttestationLetterTypes>) => {
        let formData = new FormData();

        formData.set(
          'retirementCertificateCopy',
          values?.retirementCertificateCopy as Blob
        );
        formData.set('passportPhotocopy', values?.passportPhotocopy as Blob);
        formData.set('passportPhotograph', values?.passportPhotograph as Blob);
        formData.set(
          'englandAddressProof',
          values?.englandAddressProof as Blob
        );
        formData.set('user_id', id as unknown as string);

        uploadDocument(formData as any);
      },
    });

  return (
    <Section>
      <Paragraph className="mb-5 text-neutral-copy-black text-lg">
        Attestation Letter
      </Paragraph>

      <article className="pb-10">
        <Card className="bg-white">
          <Form onSubmit={handleSubmit}>
            <Heading className={'text-primary mb-8'}>Upload Documents</Heading>

            <Section className="grid grid-cols-1 gap-y-8">
              <Uploader
                url={values.retirementCertificateCopy}
                label="Photocopy of proof of leaving service (Certificate of retirement) *"
                setUrl={setRetirementCertificateCopy}
                name="retirementCertificateCopy"
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                errorMessage={errors.retirementCertificateCopy}
                error={!!errors.retirementCertificateCopy}
              />

              <Uploader
                url={values.passportPhotocopy}
                label="Photocopy of Nigerian Passport *"
                setUrl={setPassportPhotocopy}
                name="passportPhotocopy"
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                errorMessage={errors.passportPhotocopy}
                error={!!errors.passportPhotocopy}
              />

              <Uploader
                url={values.passportPhotograph}
                label="Passport sized photograph *"
                setUrl={setPassportPhotograph}
                name="passportPhotograph"
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                errorMessage={errors.passportPhotograph}
                error={!!errors.passportPhotograph}
              />

              <Uploader
                url={values.englandAddressProof}
                label="Proof of address in England *"
                setUrl={setEnglandAddressProof}
                name="englandAddressProof"
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                errorMessage={errors.englandAddressProof}
                error={!!errors.englandAddressProof}
              />
            </Section>

            <Section className="flex items-center mt-10">
              <Button
                label="Save Application"
                className="border border-neutral-500 lg:w-56 text-neutral hover:text-dark hover:scale-105"
              />

              <Button
                className="bg-primary text-white lg:w-56 w-full ml-5 hover:scale-105"
                type="submit"
                label="Register"
                loading={isFileUploading}
              />
            </Section>
          </Form>
        </Card>
      </article>
    </Section>
  );
};

export default AttestationLetterModule;
