import Modal from '@components/elements/Modal';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import SubHeading from '@components/elements/Text/SubHeading';
import { consular } from '@store/mocks';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileServiceCard from '../service-card/MobileServiceCard';

const MoreConsularServices = () => {
  const navigate = useNavigate();
  const [openMore, setOpenMore] = useState(false);

  return (
    <>
      <button
        onClick={() => setOpenMore(true)}
        className="bg-neutral-accorion px-4 py-3 text-sm text-neutral rounded-lg hover:shadow hidden md:block"
      >
        More Consular Services
      </button>

      <button
        onClick={() => setOpenMore(true)}
        className="bg-neutral-accorion px-4 py-1 text-sm text-neutral rounded hover:shadow block md:hidden"
      >
        More
      </button>

      {openMore && (
        <Modal handleClose={() => setOpenMore(false)}>
          <Section>
            <SubHeading
              text="Consular Services"
              className="text-primary mb-1"
            />
            <Paragraph className="text-neutral font-light">
              Select the consular service you need to get started.
            </Paragraph>
            <Section className="max-h-[450px] lg:max-h-none overflow-y-auto grid md:grid-cols-2 lg:grid-cols-3 gap-4 my-6">
              {consular.map((item, idx) => {
                return (
                  <MobileServiceCard
                    key={idx}
                    icon={item.icon}
                    title={item.title}
                    subTitle={item.description}
                    buttonText={item.buttonText}
                    onClick={() => {
                      navigate(item.url);
                      setOpenMore(false);
                    }}
                  />
                );
              })}
            </Section>
          </Section>
        </Modal>
      )}
    </>
  );
};

export default MoreConsularServices;
