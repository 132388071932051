import React, { useState } from 'react';
import Card from '@components/elements/Card';
import Heading from '@components/elements/Text/Heading';
import Section from '@components/elements/Section';
import Uploader from '@components/elements/Uploader';
import { useFormik } from 'formik';
import { Student } from '@/types/Student';
import { studentsDocumentUploadSchema } from '@utils/validationSchema';
import Form from '@components/elements/Form';
import CheckBox from '@components/elements/CheckBox';
import Paragraph from '@components/elements/Text/Paragraph';
import Button from '@components/elements/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUploadStudentDocument } from '../api';
import { useAuth } from '@features/Auth/hooks/useAuthStore';

const StudentsDocumentUpload = () => {
  const [passport, setPassport] = useState<string | null>(null);
  const [nigerianPass, setNigerianPass] = useState<string | null>(null);
  const [ukVisa, setUkVisa] = useState<string | null>(null);
  const [addmissionLetter, setAddmissionLetter] = useState<string | null>(null);
  const [acceptanceLetter, setAcceptanceLetter] = useState<string | null>(null);
  const [certificate, setCertificate] = useState<string | null>(null);
  const [nysc, setNysc] = useState<string | null>(null);

  const { uploadDocument, isFileUploading } = useUploadStudentDocument();
  const [search] = useSearchParams();

  const formId = search.get('formId');
  const { id } = useAuth();

  // const studentRegPayload = useStudentRegPayload();
  const navigate = useNavigate();
  const initialValues = {
    passport_photo: passport as unknown as Blob,
    ng_passport: nigerianPass as unknown as Blob,
    uk_visa: ukVisa as unknown as Blob,
    letter_of_admission: addmissionLetter as unknown as Blob,
    letter_of_acceptance: acceptanceLetter as unknown as Blob,
    photocopies_certs: certificate as unknown as Blob,
    nysc_discharge_certs: nysc as unknown as Blob,
    accept_terms: false,
  };

  const { handleSubmit, values, errors, setFieldValue, setFieldError } =
    useFormik<Partial<Student>>({
      initialValues: initialValues,
      validationSchema: studentsDocumentUploadSchema,
      onSubmit: async (values: Partial<Student>) => {
        let formData = new FormData();
        formData.set('passport_photo', values?.passport_photo as Blob);
        formData.set('ng_passport', values.ng_passport as Blob);
        formData.set('uk_visa', values.uk_visa as Blob);
        formData.set('letter_of_admission', values.letter_of_admission as Blob);
        formData.append(
          'letter_of_acceptance',
          values.letter_of_acceptance as Blob
        );
        formData.set('photocopies_certs', values.photocopies_certs as Blob);
        formData.set(
          'nysc_discharge_certs',
          values.nysc_discharge_certs as Blob
        );
        formData.set('child_form_id', formId as unknown as string);
        formData.set('user_id', id as unknown as string);
        uploadDocument(formData as any);
        // setTeamMemberPayload(values as Student);
        // setStep({ step: 2, completed: true, active: false });
        // navigate('/students-reg/?step=student-documents-upload');
      },
    });

  return (
    <Card className="bg-white">
      <Section>
        <Heading className={'text-primary mb-4'}>Upload Documents</Heading>
      </Section>
      <Form onSubmit={handleSubmit}>
        <Section className="mb-6">
          <Uploader
            url={values.passport_photo}
            label="Passport photograph *"
            setUrl={setPassport}
            name="passport_photo"
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            errorMessage={errors.passport_photo}
            error={!!errors.passport_photo}
          />
        </Section>
        <Section className="mb-6">
          <Uploader
            url={values.ng_passport}
            label="Photocopy of Nigeria Passport *"
            setUrl={setNigerianPass}
            name="ng_passport"
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            errorMessage={errors.ng_passport}
            error={!!errors.ng_passport}
          />
        </Section>
        <Section className="mb-6">
          <Uploader
            url={values.uk_visa}
            label="Valid UK student visa *"
            setUrl={setUkVisa}
            name="uk_visa"
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            errorMessage={errors.uk_visa}
            error={!!errors.uk_visa}
          />
        </Section>
        <Section className="mb-6">
          <Uploader
            url={values.letter_of_admission}
            label="Current letter of admission indicating date of commencement and duration of course *"
            setUrl={setAddmissionLetter}
            name="letter_of_admission"
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            errorMessage={errors.letter_of_admission}
            error={!!errors.letter_of_admission}
          />
        </Section>
        <Section className="mb-6">
          <Uploader
            url={values.letter_of_acceptance}
            label="Final Letter of acceptance indicating tuition fees and Maintenance, signed and stamped by the Registrar *"
            setUrl={setAcceptanceLetter}
            name="letter_of_acceptance"
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            errorMessage={errors.letter_of_acceptance}
            error={!!errors.letter_of_acceptance}
          />
        </Section>
        <Section className="mb-6">
          <Uploader
            url={values.photocopies_certs}
            label="Photocopies of Certificates acquired to date *"
            setUrl={setCertificate}
            name="photocopies_certs"
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            errorMessage={errors.photocopies_certs}
            error={!!errors.photocopies_certs}
          />
        </Section>
        <Section className="mb-6">
          <Uploader
            url={values.nysc_discharge_certs}
            label="NYSC Discharge Certificate / Exemption Certificate (for Graduate Student from Nigeria Only)"
            setUrl={setNysc}
            name="nysc_discharge_certs"
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            errorMessage={errors.nysc_discharge_certs}
            error={!!errors.nysc_discharge_certs}
          />
        </Section>
        <Section className="flex items-center justify-between my-6">
          <Section className="self-start">
            <CheckBox
              className="h-4 w-4 md:w-5 md:h-5 checkbox-success text-white"
              name={'accept_terms'}
              checked={values.accept_terms}
              onChange={(e) => {
                setFieldValue(e.target.name, e.target.checked);
              }}
            />
          </Section>
          <Paragraph className="ml-3">
            I declare that the information above is correct, and that I may be
            disqualified from any services if discovered otherwise also note
            that if your picture is not the required one your details will be
            deleted.
          </Paragraph>
        </Section>
        {errors.accept_terms && (
          <Paragraph className="text-red-600">{errors.accept_terms}</Paragraph>
        )}
        <Section className="flex items-center">
          <Button
            className="border border-neutral lg:w-64 w-full"
            label="Back"
            onClick={() => navigate(-1)}
          />
          <Button
            className="border bg-primary lg:w-64 w-full ml-6"
            label="Upload Documents"
            type="submit"
            loading={isFileUploading}
          />
        </Section>
      </Form>
    </Card>
  );
};

export default StudentsDocumentUpload;
