/***
 *
 * ? Local & shared Import
 * @returns
 * @function Hook:::useAuthSignin
 * @function Hook:::useAuthSignup
 */

import { SignInResponse } from '@/types/User';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axios } from 'lib';

export type SignInCredentials = {
  email: string;
  password: string;
};

export type SignUpPayload = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  password_confirmation: string;
};

type SignUpResponse = {
  token: string;
};

const useSignInMutation = (): UseMutationResult<
  SignInResponse,
  AxiosError,
  SignInCredentials
> => {
  const mutation = useMutation<SignInResponse, AxiosError, SignInCredentials>(
    async (data: SignInCredentials) => {
      try {
        const response = await axios.post('/login', data);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );
  return mutation;
};

const useSignUpMutation = (): UseMutationResult<
  SignUpResponse,
  AxiosError,
  SignUpPayload
> => {
  const mutation = useMutation<SignUpResponse, AxiosError, SignUpPayload>(
    async (data: SignUpPayload) => {
      try {
        const response = await axios.post('register', data);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

  return mutation;
};

export { useSignUpMutation, useSignInMutation };
