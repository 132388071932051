import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  EtcTypes,
  NonImpedimentTypes,
  PoliceClearanceTypes,
} from '@/types/Consular';
import {
  policeClearancePayload,
  nonImpedimentPayload,
  setNonImpedimentPayload,
  setPoliceClearancePayload,
  setEtcPayload,
  etcPayload,
} from '../Consular.slice';

export const useConsularActions = () => {
  const dispatch = useAppDispatch();
  return {
    setPoliceClearancePayload: useCallback(
      (policeClearancePayload: PoliceClearanceTypes) =>
        dispatch(setPoliceClearancePayload(policeClearancePayload)),
      [dispatch]
    ),
    setNonImpedimentPayload: useCallback(
      (nonImpedimentPayload: NonImpedimentTypes) =>
        dispatch(setNonImpedimentPayload(nonImpedimentPayload)),
      [dispatch]
    ),
    setEtcPayload: useCallback(
      (etcPayload: EtcTypes) => dispatch(setEtcPayload(etcPayload)),
      [dispatch]
    ),
  };
};

export const usePoliceClearancePayload = () =>
  useAppSelector(policeClearancePayload);
export const useNonImpedimentPayload = () =>
  useAppSelector(nonImpedimentPayload);
export const useEtcPayload = () => useAppSelector(etcPayload);
