import React from 'react';
import NonImpedimentPayment from './NonImpedimentPayment';
import UploadDocuments from './UploadDocuments';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import { useSearchParams } from 'react-router-dom';
import Span from '@components/elements/Span';

const NonImpedimentModule = () => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get('step') ?? 'upload-documents';

  const routes: { [key: string]: JSX.Element } = {
    'upload-documents': <UploadDocuments />,
    payment: <NonImpedimentPayment />,
  };

  const routeHeader: {
    [key: string]: { title: string; description: string };
  } = {
    'upload-documents': {
      title: 'Non-Impediment to Marry',
      description: 'Document Uploads',
    },
    payment: {
      title: 'Non-Impediment to Marry',
      description: 'Payment',
    },
  };

  return (
    <Section>
      <Section className="my-4">
        <Paragraph className="text-neutral-copy-black text-lg">
          {routeHeader[step].title} -{' '}
          <Span className="font-light text-sm">
            {routeHeader[step].description}
          </Span>
        </Paragraph>
      </Section>

      {routes[step]}
    </Section>
  );
};

export default NonImpedimentModule;
