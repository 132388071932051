import Section from '@components/elements/Section';
import SigninForm from './form';
import Services from './services';

const Signin = () => {
  return (
    <Section className="grid lg:grid-cols-3 grid-cols-1">
      <Section className="col-span-1 lg:h-screen overflow-y-auto scrollbar-hide bg-white hidden lg:block">
        <Services />
      </Section>
      <Section className="col-span-2 h-full lg:h-screen flex items-center justify-center">
        <SigninForm />
      </Section>
    </Section>
  );
};
export default Signin;
