import { NonImpedimentTypes } from '@/types/Consular';
import Button from '@components/elements/Button';
import Card from '@components/elements/Card';
import Form from '@components/elements/Form';
import ListGroup from '@components/elements/ListGroup';
import ListItem from '@components/elements/Listitem';
import Section from '@components/elements/Section';
import Heading from '@components/elements/Text/Heading';
import Paragraph from '@components/elements/Text/Paragraph';
import Uploader from '@components/elements/Uploader';
import { useAuth } from '@features/Auth/hooks/useAuthStore';
import { useNonImpedimentDocs } from '@features/Consular/api';
import { uploadDocumentsSchema } from '@utils/validationSchema/nonImpedimentSchema';
import { useFormik } from 'formik';
import { useState } from 'react';

const UploadDocuments = () => {
  const { id } = useAuth();
  const { uploadDocument, isFileUploading } = useNonImpedimentDocs();

  const [passportPhotocopy, setPassportPhotocopy] = useState<string | null>(
    null
  );
  const [proofOfDivorce, setProofOfDivorce] = useState<string | null>(null);
  const [notorizedDeclaration, setNotorizedDeclaration] = useState<
    string | null
  >(null);
  const [proofOfMarriage, setProofOfMarriage] = useState<string | null>(null);

  const intitalValues: Partial<NonImpedimentTypes> = {
    proofOfDivorce: proofOfDivorce as unknown as Blob,
    passportPhotocopy: passportPhotocopy as unknown as Blob,
    notorizedDeclaration: notorizedDeclaration as unknown as Blob,
    proofOfMarriage: proofOfMarriage as unknown as Blob,
  };

  const { handleSubmit, values, errors, setFieldValue, setFieldError } =
    useFormik<Partial<NonImpedimentTypes>>({
      initialValues: intitalValues,
      validationSchema: uploadDocumentsSchema,
      onSubmit: async (values: Partial<NonImpedimentTypes>) => {
        let formData = new FormData();

        formData.set('proofOfDivorce', values?.proofOfDivorce as Blob);
        formData.set('passportPhotocopy', values?.passportPhotocopy as Blob);
        formData.set(
          'notorizedDeclaration',
          values?.notorizedDeclaration as Blob
        );
        formData.set('proofOfMarriage', values?.proofOfMarriage as Blob);
        formData.set('user_id', id as unknown as string);

        uploadDocument(formData as any);
      },
    });

  return (
    <div className="pb-10">
      <Card>
        <Form onSubmit={handleSubmit}>
          <Heading className={'text-primary mb-8'}>Upload Documents</Heading>

          <Section className="grid grid-cols-1 gap-y-8">
            <Uploader
              url={values.proofOfDivorce}
              label="Proof of Divorce (Decree Absolute)"
              setUrl={setProofOfDivorce}
              name="proofOfDivorce"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.proofOfDivorce}
              error={!!errors.proofOfDivorce}
            />

            <Uploader
              url={values.passportPhotocopy}
              label="Photocopy of Nigerian Passport *"
              setUrl={setPassportPhotocopy}
              name="passportPhotocopy"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.passportPhotocopy}
              error={!!errors.passportPhotocopy}
            />

            <Uploader
              url={values.notorizedDeclaration}
              label="Notarized Declaration of Non-impediment to marry *"
              setUrl={setNotorizedDeclaration}
              name="notorizedDeclaration"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.notorizedDeclaration}
              error={!!errors.notorizedDeclaration}
            />

            <Uploader
              url={values.proofOfMarriage}
              label="Proof of Impeding marriage *"
              setUrl={setProofOfMarriage}
              name="proofOfMarriage"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errorMessage={errors.proofOfMarriage}
              error={!!errors.proofOfMarriage}
            />

            <Paragraph className="bg-gray-200 text-gray-500 border border-[#EDF1EB] py-4 px-6 font-medium rounded-md">
              Letters of non-impediment are issued to;{' '}
              <ListGroup className="list-decimal ml-5">
                <ListItem>
                  Nigerian nationals seeking to either marry for the first time
                  in Britain or to those who are seeking to re-marry.
                </ListItem>
                <ListItem>
                  For those who have never been married, proof of permission to
                  marry from the Home Office will be required with the rest of
                  the requirements stated above.
                </ListItem>
              </ListGroup>
            </Paragraph>
          </Section>

          <Section className="mt-5">
            <Button
              className="bg-primary text-white lg:w-56 w-full hover:scale-105"
              type="submit"
              label="Next"
              loading={isFileUploading}
            />
          </Section>
        </Form>
      </Card>
    </div>
  );
};

export default UploadDocuments;
