import {
  applicationActive,
  applicationBase,
  helpActive,
  helpBase,
  logo,
  logout,
  profileIcon,
  serviceActive,
  serviceBase,
  studentBase,
  studentsActive,
  userActive,
} from '@assets/icons';
import Image from '@components/elements/Image';
import SidebarMenuItem from '@components/elements/Menu/SidebarMenuItem';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import appRoutes from '@components/router/appRoutes';
import { useAuthActions } from '@features/Auth/hooks/useAuthStore';

import { useLocation, useNavigate } from 'react-router-dom';

const SideNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuthActions();

  return (
    <div
      className={`bg-white pl-8 py-8 w-[380px] md:w-72 h-full hidden lg:flex flex-col justify-between fixed overflow-y-auto z-50 transition-all ease-in-out duration-300`}
    >
      <div>
        <a href={appRoutes.WEBSITE} target="_blank" rel="noreferrer">
          <img src={logo} alt="light logo" />
        </a>
      </div>
      <aside className="mt-24">
        <div className="mb-2">
          <SidebarMenuItem
            title="Consular"
            active={[
              appRoutes.CONSULAR_SERVICES,
              appRoutes.DOCUMENT_AUTH,
              appRoutes.POLICE_CLEARANCE,
              appRoutes.PAYMENT,
              '/',
            ].includes(location?.pathname)}
            url="/"
            activeIcon={serviceActive}
            baseIcon={serviceBase}
          />
        </div>
        {/* <div className="mb-2">
          <SidebarMenuItem
            title="Applications"
            active={[appRoutes.APPLICATIONS].includes(location?.pathname)}
            url={appRoutes.APPLICATIONS}
            activeIcon={applicationActive}
            baseIcon={applicationBase}
          />
        </div> */}
        <div className="mb-2">
          <SidebarMenuItem
            title="Students"
            active={[appRoutes.STUDENT_REG].includes(location?.pathname)}
            url={appRoutes.STUDENT_REG}
            activeIcon={studentsActive}
            baseIcon={studentBase}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="Profile"
            active={location.pathname === appRoutes.PROFILE}
            url="/profile"
            activeIcon={userActive}
            baseIcon={profileIcon}
          />
        </div>
        <div className="mb-2">
          <SidebarMenuItem
            title="FAQ"
            active={location.pathname === '/faq'}
            url="/faq"
            activeIcon={helpActive}
            baseIcon={helpBase}
          />
        </div>
        {/* <div className="mb-2">
          <SidebarMenuItem
            title="Contact Us"
            active={location.pathname === '/contact'}
            url="/contact"
            activeIcon={userActive}
            baseIcon={profileIcon}
          />
        </div> */}
      </aside>
      <Section
        className="mt-auto flex items-center cursor-pointer"
        onClick={() => {
          setUser(null);
          sessionStorage.clear();
          return navigate('/signin');
        }}
      >
        <Image src={logout} alt="logout | Nigerian High Commission London UK" />{' '}
        <Paragraph className="ml-2">Logout</Paragraph>
      </Section>
    </div>
  );
};

export default SideNav;
