import { Student } from '@/types/Student';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/types';

export interface StudentState {
  studentRegPayload: Student | null;
}

const initialState: StudentState = {
  studentRegPayload: null,
};

export const StudentRegSlice = createSlice({
  name: 'student-reg-payload',
  initialState,
  reducers: {
    setStudentRegPayload: (
      state: StudentState,
      { payload }: PayloadAction<Student>
    ) => {
      state.studentRegPayload = { ...state.studentRegPayload, ...payload };
    },
  },
});

export const { setStudentRegPayload } = StudentRegSlice.actions;
export const studentRegPayload = (state: RootState) => state.studentReg;
export default StudentRegSlice;
