import React from 'react';

interface StepperItemProps {
  label: string | number;
  active: boolean;
  completed: boolean;
  highlighted: boolean;
  lastChild?: boolean;
}

const StepperItem: React.FC<StepperItemProps> = ({
  label,
  active,
  completed,
  highlighted,
  lastChild,
}) => {
  return (
    <section className="flex items-center">
      <div
        className={`sm:h-[40px] sm:w-[40px] w-[30px] h-[30px] rounded-full  border-2 flex items-center justify-center ${
          active && ' border-primary'
        } ${completed && 'bg-primary text-white border-primary'}`}
      >
        {completed ? (
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.62598 4.625L4.12598 7.125L10.376 0.875"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : active ? (
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="3.75" fill="#227645" />
          </svg>
        ) : (
          <p>{label || 1}</p>
        )}
      </div>
      {lastChild ? null : (
        <div
          className={`lg:w-full sm:w-full w-20 h-[2px] bg-[#D1D5DB] flex-auto ${
            (active || completed) &&
            'border-primary border bg-primary text-white h-[2px]'
          }`}
        ></div>
      )}
    </section>
  );
};

export default StepperItem;
