const appRoutes = {
  WEBSITE: 'https://www.nigeriahc.org.uk/',
  HOME: '/',
  TEST: '/test',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  STUDENT_REG: '/students-reg',
  CONSULAR_SERVICES: '/consular',
  POLICE_CLEARANCE: '/consular/police-clearance',
  CITIZENSHIP_RENUNCIATION: '/consular/citizenship-renunciation',
  ATTESTATION_LETTER: '/consular/attestation-letter',
  NON_IMPEDIMENT: '/consular/non-impediment',
  DOCUMENT_AUTH: '/document-auth',
  ETC: '/consular/emergency-travel-certificate',
  FAQ: '/faq',
  CONTACT: '/contact',
  PROFILE: '/profile',
  PAYMENT: '/payment',
  APPLICATIONS: '/applications',
};

export default appRoutes;
