import Card from '@components/elements/Card';
import Heading from '@components/elements/Text/Heading';
import TextField from '@components/elements/TextField';
import FormContainer from '@components/elements/FormContainer';
import Section from '@components/elements/Section';
import Button from '@components/elements/Button';
import Paragraph from '@components/elements/Text/Paragraph';
import { Link, useNavigate } from 'react-router-dom';
import Image from '@components/elements/Image';
import { eyeIcon, logo } from '@assets/icons';
import { SignUpPayload, useSignUpMutation } from '@features/Auth/api';
import { useFormik } from 'formik';
import { signUpValidationSchema } from '@utils/validationSchema';
import Form from '@components/elements/Form';
import { toast } from 'react-toastify';
import { handleError } from '@utils/error';
import { useState } from 'react';

const SignupForm = () => {
  const { mutate, isLoading } = useSignUpMutation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { handleSubmit, values, handleChange, errors, touched } =
    useFormik<SignUpPayload>({
      initialValues: {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        password_confirmation: '',
      },
      validationSchema: signUpValidationSchema,
      onSubmit: async (values: SignUpPayload): Promise<void> => {
        mutate(values, {
          onSuccess: () => {
            toast.success('Registration Successful');
            navigate('/signin');
          },
          onError: (error) => {
            const message = handleError(
              (error.response?.data as any).error_msg
            );
            toast.error(message);
          },
        });
      },
    });

  return (
    <Card className="bg-white lg:rounded-3xl w-full lg:w-[532px]">
      <Form onSubmit={handleSubmit}>
        <Section className="flex items-center justify-center">
          <Image src={logo} alt="Nigerian High Commission London UK" />
        </Section>
        <Section>
          <Heading className={'text-primary my-7 text-center'}>
            Create an Account
          </Heading>
          <FormContainer className="mb-6">
            <TextField
              placeholder="Enter firstname"
              label="First Name"
              name="firstname"
              value={values.firstname}
              onChange={handleChange}
              error={!!errors.firstname && touched.firstname}
              message={errors.firstname}
            />
            <TextField
              placeholder="Enter last name"
              label="Last Name"
              value={values.lastname}
              onChange={handleChange}
              name="lastname"
              error={!!errors.lastname && touched.lastname}
              message={errors.lastname}
            />
          </FormContainer>
          <Section className="mb-6">
            <TextField
              placeholder="e.g xxxx@example.com"
              label="Enter Email"
              type="email"
              value={values.email}
              name="email"
              onChange={handleChange}
              error={!!errors.email && touched.email}
              message={errors.email}
            />
          </Section>
          <Section>
            <TextField
              placeholder="*********"
              label="Create Password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              name="password"
              onChange={handleChange}
              error={!!errors.password && touched.password}
              message={errors.password}
              icon={
                <img
                  onClick={() => setShowPassword(!showPassword)}
                  src={eyeIcon}
                  height={24}
                  width={24}
                  alt="password icon"
                />
              }
            />
          </Section>
          <Section className="my-6">
            <TextField
              placeholder="*********"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              value={values.password_confirmation}
              onChange={handleChange}
              name="password_confirmation"
              error={
                !!errors.password_confirmation && touched.password_confirmation
              }
              message={errors.password_confirmation}
              icon={
                <img
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  src={eyeIcon}
                  height={24}
                  width={24}
                  alt="password icon"
                />
              }
            />
          </Section>
          <Section className="mb-6">
            <Button
              type="submit"
              label="Create Account"
              className="bg-primary text-white"
              loading={isLoading}
            />
          </Section>
          <Paragraph className="text-center text-neutral-copy-black">
            Already have an account, please
            <Link to="/signin" className="text-primary ml-2">
              Login
            </Link>
          </Paragraph>
        </Section>
      </Form>
    </Card>
  );
};

export default SignupForm;
