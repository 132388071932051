import { EtcTypes } from '@/types/Consular';
import Card from '@components/elements/Card';
import Form from '@components/elements/Form';
import FormContainer from '@components/elements/FormContainer';
import Section from '@components/elements/Section';
import Heading from '@components/elements/Text/Heading';
import TextField from '@components/elements/TextField';
import { useFormik } from 'formik';
import SelectDropdown from '@components/elements/Select';
import { GenderOptions, MaritalStatusOptions, TypeOptions } from '@utils/data';
import Button from '@components/elements/Button';
import { personalDetailsSchema } from '@utils/validationSchema/emergencyTravelSchema';
import { useGetStates } from '@hooks/useStateLga';
import { useMemo } from 'react';
import { getLga } from '@store/mocks';
import TextArea from '@components/elements/TextArea';

import { useNavigate } from 'react-router-dom';
import { useConsularActions } from '@features/Consular/hooks/useConsularActions';

const PersonalDetails = () => {
  const { states } = useGetStates();
  const { setEtcPayload } = useConsularActions();

  const navigate = useNavigate();
  const intitalValues: Partial<EtcTypes> = {
    title: '',
    fullname: '',
    surname: '',
    otherName: '',
    type: '',
    gender: '',
    dob: '' as unknown as Date,
    pob: '',
    addressInNigeria: '',
    stateOfOrigin: '',
    lga: '',
    phone: '',
    occupation: '',
    permanentAddress: '',
    country: '',
    nationality: '',
    whereYouReside: '',
    maritalStatus: '',
    dateLastResidenceInNig: '' as unknown as Date,
    postcode: '' as unknown as number,
    reasonInUk: '',
    reasonForRequest: '',
    passportNo: '',
    dateOfIssue: '' as unknown as Date,
    placeOfIssue: '',
  };

  const { handleSubmit, values, handleChange, errors, touched, setFieldValue } =
    useFormik<Partial<EtcTypes>>({
      initialValues: intitalValues,
      validationSchema: personalDetailsSchema,
      onSubmit: async (values: Partial<EtcTypes>) => {
        navigate(
          '/consular/emergency-travel-certificate/?step=next-of-details'
        );
        setEtcPayload(values as EtcTypes);
      },
    });

  const lga = useMemo(() => getLga((values as any)?.stateOfOrigin), [values]);

  return (
    <div className="pb-10">
      <Card className="bg-white">
        <Form onSubmit={handleSubmit}>
          <Heading className={'text-primary mb-8'}>Biographic Details</Heading>
          <Section className="grid grid-3">
            <FormContainer>
              <TextField
                placeholder="Eg; Mr, Mrs, Miss etc "
                label="Title *"
                name="title"
                value={values.title}
                error={!!errors?.title && touched?.title}
                onChange={handleChange}
                message={errors.title}
              />
              <TextField
                placeholder="Surname"
                label="Surname *"
                name="surname"
                value={values.surname}
                error={!!errors?.surname && touched?.surname}
                onChange={handleChange}
                message={errors.surname}
              />

              <TextField
                placeholder="Eg; Olufemi"
                label="First Name *"
                name="fullname"
                value={values.fullname}
                error={!!errors?.fullname && touched?.fullname}
                onChange={handleChange}
                message={errors.fullname}
              />

              <TextField
                placeholder="Other name (if any)"
                label="Other Name"
                name="otherName"
                value={values.otherName}
                error={!!errors?.otherName && touched?.otherName}
                onChange={handleChange}
                message={errors.otherName}
              />

              <SelectDropdown
                options={GenderOptions}
                label="Gender *"
                name="gender"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('gender', value);
                }}
                defaultValue={values.gender}
                error={!!errors.gender && touched.gender}
                errorMessage={errors.gender}
              />

              <SelectDropdown
                options={TypeOptions}
                label="Type *"
                name="type"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('type', value);
                }}
                defaultValue={values.type}
                error={!!errors.type && touched.type}
                errorMessage={errors.type}
                placeholder="Select Type Minor, or Adult"
              />

              <TextField
                placeholder="Enter date of birth"
                label="Date of Birth *"
                type="date"
                name="dob"
                value={values.dob}
                error={!!errors?.dob && touched?.dob}
                onChange={handleChange}
                message={errors.dob}
              />

              <TextField
                placeholder="Enter place of birth"
                label="Place of birth *"
                name="pob"
                value={values.pob}
                error={!!errors?.pob && touched?.pob}
                onChange={handleChange}
                message={errors.pob}
              />

              <SelectDropdown
                options={states}
                label="State of Origin"
                name="stateOfOrigin"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('stateOfOrigin', value);
                }}
                defaultValue={values.stateOfOrigin}
                error={!!errors.stateOfOrigin && touched.stateOfOrigin}
                errorMessage={errors.stateOfOrigin}
              />

              <SelectDropdown
                options={lga}
                label="LGA of Origin"
                name="lga"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('lga', value);
                }}
                defaultValue={values.lga}
                error={!!errors.lga && touched.lga}
                errorMessage={errors.lga}
              />

              <TextField
                placeholder="+234XXXXXXXXX"
                label="Phone Number"
                type="text"
                name="phone"
                value={values.phone}
                error={!!errors.phone && touched.phone}
                message={errors.phone}
                onChange={handleChange}
              />

              <TextField
                placeholder="Present employment"
                label="Present Employment *"
                name="occupation"
                value={values.occupation}
                error={!!errors?.occupation && touched?.occupation}
                onChange={handleChange}
                message={errors.occupation}
              />

              <TextField
                placeholder="Permanent Address"
                label="Permanent Address *"
                name="permanentAddress"
                value={values.permanentAddress}
                error={!!errors?.permanentAddress && touched?.permanentAddress}
                onChange={handleChange}
                message={errors.permanentAddress}
              />

              <TextField
                placeholder="Enter country"
                label="Country *"
                name="country"
                value={values.country}
                error={!!errors?.country && touched?.country}
                onChange={handleChange}
                message={errors.country}
              />

              <TextField
                placeholder="Eg; Nigerian"
                label="Nationality *"
                name="nationality"
                value={values.nationality}
                error={!!errors?.nationality && touched?.nationality}
                onChange={handleChange}
                message={errors.nationality}
              />

              <TextField
                placeholder="Eg; Nothingham"
                label="Where you reside *"
                name="whereYouReside"
                value={values.whereYouReside}
                error={!!errors?.whereYouReside && touched?.whereYouReside}
                onChange={handleChange}
                message={errors.whereYouReside}
              />

              <SelectDropdown
                options={MaritalStatusOptions}
                label="Marital Status *"
                name="maritalStatus"
                onChange={({ value }: { value: string }) => {
                  setFieldValue('maritalStatus', value);
                }}
                defaultValue={values.maritalStatus}
                error={!!errors.maritalStatus && touched.maritalStatus}
                errorMessage={errors.maritalStatus}
              />

              <TextField
                placeholder="Enter last date in Nigeria"
                label="Last Date In Nigeria *"
                type="date"
                name="dateLastResidenceInNig"
                value={values.dateLastResidenceInNig}
                error={
                  !!errors?.dateLastResidenceInNig &&
                  touched?.dateLastResidenceInNig
                }
                onChange={handleChange}
                message={errors.dateLastResidenceInNig}
              />

              <TextField
                placeholder="Enter Post Code"
                label="Post Code *"
                name="postcode"
                value={values.postcode}
                error={!!errors?.postcode && touched?.postcode}
                onChange={handleChange}
                message={errors.postcode}
              />

              <TextField
                placeholder="Enter passport number"
                label="Passport Number *"
                name="passportNo"
                value={values.passportNo}
                error={!!errors?.passportNo && touched?.passportNo}
                onChange={handleChange}
                message={errors.passportNo}
              />

              <TextField
                placeholder="Date of issue"
                label="Date of Passport Issue *"
                name="dateOfIssue"
                type="date"
                value={values.dateOfIssue}
                error={!!errors?.dateOfIssue && touched?.dateOfIssue}
                onChange={handleChange}
                message={errors.dateOfIssue}
              />

              <TextField
                placeholder="Place of issue"
                label="Place of Passport Issue *"
                name="placeOfIssue"
                value={values.placeOfIssue}
                error={!!errors?.placeOfIssue && touched?.placeOfIssue}
                onChange={handleChange}
                message={errors.placeOfIssue}
              />

              <TextField
                placeholder="Enter address in Nigeria"
                label="Address when in Nigeria in Full *"
                name="addressInNigeria"
                value={values.addressInNigeria}
                error={!!errors?.addressInNigeria && touched?.addressInNigeria}
                onChange={handleChange}
                message={errors.addressInNigeria}
              />
            </FormContainer>
            <FormContainer className="mt-6">
              <TextArea
                placeholder=" Brief reason why you're in UK"
                label="Enter Reason In UK "
                name="reasonInUk"
                textAreaName="reasonInUk"
                value={values.reasonInUk}
                error={!!errors?.reasonInUk && touched?.reasonInUk}
                onChange={handleChange}
              />
              <TextArea
                placeholder=" Brief reason why you're requesting for ETC"
                label="Enter Reason for ETC request"
                name="reasonForRequest"
                textAreaName="reasonForRequest"
                value={values.reasonForRequest}
                error={!!errors?.reasonForRequest && touched?.reasonForRequest}
                onChange={handleChange}
              />
            </FormContainer>
            <Section className="mt-10">
              <Button
                className="bg-primary text-white lg:w-56 w-full"
                type="submit"
                label="Next"
              />
            </Section>
          </Section>
        </Form>
      </Card>
    </div>
  );
};

export default PersonalDetails;
