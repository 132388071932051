import { docs } from '@assets/icons';
import Button from '@components/elements/Button';
import Image from '@components/elements/Image';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';
import SubHeading from '@components/elements/Text/SubHeading';
import React from 'react';

export interface ServiceCardProps {
  icon: string;
  title: string;
  subTitle: string;
  buttonText: string;
  onClick?: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  icon,
  title,
  subTitle,
  buttonText,
  onClick,
}) => {
  return (
    <Section className="w-full flex flex-col justify-between bg-white px-5 py-6 rounded-2xl lg:min-h-[300px] lg:max-w-[450px]">
      <Section>
        <Image src={docs} alt={'Docs Icon'} />
      </Section>
      <Section className="mt-4 mb-9">
        <SubHeading
          className="text-neutral-copy-black font-medium mb-3"
          text={title || 'Students Registration'}
        />
        <Paragraph className="text-neutral-copy-black/80 font-light">
          {subTitle || 'Non-Impediment to Marry'}
        </Paragraph>
      </Section>
      <Section>
        <Button
          label={<span className="text-sm">{buttonText}</span>}
          onClick={onClick}
          className="w-fit px-3 !py-2 bg-neutral-accorion text-neutral hover:bg-success/20"
        />
      </Section>
    </Section>
  );
};

export default ServiceCard;
