import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';

const DocumentAuthModule = () => {
  return (
    <Section>
      <Paragraph className="text-neutral-copy-black text-lg">
        Document Authentication
      </Paragraph>
      <Section className="mt-5 p-6 text-dark font-light bg-white rounded">
        For authentication of Marriage, Birth and Death Certificates from
        Nigeria, service is charged at £20.00 per item, and £20.00 processing
        fee payable to Nigeria High Commission London in Postal Order. However,
        Document from Nigeria for authentification should undergo authentication
        in the Ministry of Foreign Affairs Abuja before submitting such in the
        High Commission for final authentication.
      </Section>
    </Section>
  );
};

export default DocumentAuthModule;
