import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import type { User } from '@/types/User';
import { setUser, user } from '../Auth.slice';

export const useAuthActions = () => {
  const dispatch = useAppDispatch();
  return {
    setUser: useCallback(
      (user: User | null) => dispatch(setUser(user)),
      [dispatch]
    ),
  };
};

export const useAuth = () => {
  return useAppSelector(user);
};
