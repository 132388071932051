import Card from '@components/elements/Card';
import CheckBox from '@components/elements/CheckBox';
import Section from '@components/elements/Section';
import Paragraph from '@components/elements/Text/Paragraph';

const Terms = () => {
  return (
    <Card>
      <Section className="flex items-center justify-between mb-6">
        <CheckBox
          className="h-4 w-4 md:w-5 md:h-5 checkbox-success text-white"
          name={''}
          value={''}
          checked={true}
        />
        <Paragraph className="ml-3">
          I declare that the information above is correct, and that I may be
          disqualified from any services if discovered otherwise also note that
          if your picture is not the required one your details will be deleted.
        </Paragraph>
      </Section>
    </Card>
  );
};

export default Terms;
