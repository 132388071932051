import { detailDummy } from '@assets/icons';
import Image from '@components/elements/Image';
import Section from '@components/elements/Section';
import TextField from '@components/elements/TextField';
import { updateProfileSchema } from '@utils/validationSchema';
import { useFormik } from 'formik';
import { useFetchChildren, useUpdateProfile } from '../api';
import { useAuth } from '@features/Auth/hooks/useAuthStore';
import Form from '@components/elements/Form';
import Button from '@components/elements/Button';
import FormContainer from '@components/elements/FormContainer';
import { ChildrenProps, UserUpdate } from '@/types/User';
import SelectDropdown from '@components/elements/Select';
import { GenderOptions } from '@utils/data';
import AddChild from './AddChild';
import { Key, useState } from 'react';
import BtnLoader from '@components/elements/Button/loader';
import Paragraph from '@components/elements/Text/Paragraph';

const DetailHeader = ({ title }: { title: string }) => {
  return (
    <div className="flex items-center">
      <div className="uppercase text-xs text-success font-bold">{title}</div>
      <div className="ml-2 bg-success w-20 h-[1px]"></div>
    </div>
  );
};

const ProfileModule = () => {
  const {
    id: user_id,
    address1,
    address2,
    dob,
    firstname,
    lastname,
    nigeriaAddress,
    passportNo,
    phoneNumber,
    postcode,
    sex,
    stateOfOrigin,
  } = useAuth();

  const intitalValues: Partial<UserUpdate> = {
    firstname: firstname || '',
    lastname: lastname || '',
    dob: (dob || '') as unknown as Date,
    sex: (sex || '') as unknown as 'male',
    stateOfOrigin: stateOfOrigin || '',
    passportNo: passportNo || '',
    phoneNumber: phoneNumber || '',
    address1: address1 || '',
    address2: address2 || '',
    city: '',
    postcode: postcode || '',
    nigeriaAddress: nigeriaAddress || '',
  };

  const { update, isLoading } = useUpdateProfile();
  const { children, fetchingChildren } = useFetchChildren();
  const [addChild, setAddChild] = useState<boolean>(false);

  const { handleSubmit, values, handleChange, errors, touched, setFieldValue } =
    useFormik<Partial<UserUpdate>>({
      initialValues: intitalValues,
      validationSchema: updateProfileSchema,
      onSubmit: async (values: Partial<UserUpdate>) => {
        update({ ...values, user_id });
      },
    });

  return (
    <div>
      {addChild && <AddChild handleClose={() => setAddChild(false)} />}
      <Form onSubmit={handleSubmit}>
        <Section className="pb-10 lg:grid grid-cols-12 gap-x-10 xl:gap-x-20">
          <div className="col-span-5 xl:col-span-4">
            <div className="mb-5">
              <Image src={detailDummy} className="w-full" alt="profile pic" />
            </div>
            <div className="mb-5">
              <DetailHeader title="Personal Info" />
              <div className="mt-5 grid gap-5">
                <TextField
                  label="First Name"
                  placeholder="Enter first name"
                  type="text"
                  name="firstname"
                  value={values.firstname}
                  error={!!errors?.firstname && touched?.firstname}
                  onChange={handleChange}
                  message={errors.firstname}
                />
                <TextField
                  label="Last Name"
                  placeholder="Enter last name"
                  type="text"
                  name="lastname"
                  value={values.lastname}
                  error={!!errors?.lastname && touched?.lastname}
                  onChange={handleChange}
                  message={errors.lastname}
                />
                <SelectDropdown
                  options={GenderOptions}
                  label="Gender *"
                  name="gender"
                  onChange={({ value }: { value: string }) => {
                    setFieldValue('sex', value);
                  }}
                  defaultValue={values.sex}
                  error={!!errors.sex && touched.sex}
                  errorMessage={errors.sex}
                />
                <TextField
                  label="Date of birth"
                  placeholder="Enter date of birth"
                  type="date"
                  name="dob"
                  value={values.dob}
                  error={!!errors?.dob && touched?.dob}
                  onChange={handleChange}
                  message={errors.dob}
                />
              </div>
            </div>
            <div className="mt-8">
              <DetailHeader title="Contact Information" />
              <Section className="mt-8">
                <Section className="mb-6">
                  <TextField
                    label="Phone Number"
                    placeholder="Enter phone number"
                    type="text"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    error={!!errors?.phoneNumber && touched?.phoneNumber}
                    onChange={handleChange}
                    message={errors.phoneNumber}
                  />
                </Section>

                <Section className="mb-6">
                  <TextField
                    label="First Address"
                    placeholder="Enter first address"
                    type="text"
                    name="address1"
                    value={values.address1}
                    error={!!errors?.address1 && touched?.address1}
                    onChange={handleChange}
                    message={errors.address1}
                  />
                </Section>
                <Section className="mb-6">
                  <TextField
                    label="Second Address"
                    placeholder="Enter second address"
                    type="text"
                    name="address2"
                    value={values.address2}
                    error={!!errors?.address2 && touched?.address2}
                    onChange={handleChange}
                    message={errors.address2}
                  />
                </Section>
                <Section className="mb-6">
                  <TextField
                    label="State"
                    placeholder="Enter state of origin"
                    type="text"
                    name="stateOfOrigin"
                    value={values.stateOfOrigin}
                    error={!!errors?.stateOfOrigin && touched?.stateOfOrigin}
                    onChange={handleChange}
                    message={errors.stateOfOrigin}
                  />
                </Section>
                <Section className="mb-6">
                  <TextField
                    label="City"
                    placeholder="Enter city"
                    type="text"
                    name="city"
                    value={values.city}
                    error={!!errors?.city && touched?.city}
                    onChange={handleChange}
                    message={errors.city}
                  />
                </Section>
                <Section className="mb-6">
                  <TextField
                    label="Passport Number"
                    placeholder="Enter passport number"
                    type="text"
                    name="passportNo"
                    value={values.passportNo}
                    error={!!errors?.passportNo && touched?.passportNo}
                    onChange={handleChange}
                    message={errors.passportNo}
                  />
                </Section>
                <TextField
                  label="Address in Nigeria"
                  placeholder="Enter address in Nigeria"
                  type="text"
                  name="nigeriaAddress"
                  value={values.nigeriaAddress}
                  error={!!errors?.nigeriaAddress && touched?.nigeriaAddress}
                  onChange={handleChange}
                  message={errors.nigeriaAddress}
                />
              </Section>
            </div>
          </div>

          <div className="col-span-7 xl:col-span-8">
            <div className="col-span-7 xl:col-span-8">
              <DetailHeader title="Spouse Info" />
              <FormContainer className="mt-5">
                <TextField
                  label="Phone Number"
                  placeholder="Enter phone number"
                  type="text"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  error={!!errors?.phoneNumber && touched?.phoneNumber}
                  onChange={handleChange}
                  message={errors.phoneNumber}
                />
                <SelectDropdown
                  options={GenderOptions}
                  label="Gender *"
                  name="gender"
                  onChange={({ value }: { value: string }) => {
                    setFieldValue('sex', value);
                  }}
                  defaultValue={values.sex}
                  error={!!errors.sex && touched.sex}
                  errorMessage={errors.sex}
                />

                <TextField
                  label="Date of birth"
                  placeholder="Enter date of birth"
                  type="date"
                  name="dob"
                  value={values.dob}
                  error={!!errors?.dob && touched?.dob}
                  onChange={handleChange}
                  message={errors.dob}
                />

                <TextField
                  label="First Address"
                  placeholder="Enter first address"
                  type="text"
                  name="address1"
                  value={values.address1}
                  error={!!errors?.address1 && touched?.address1}
                  onChange={handleChange}
                  message={errors.address1}
                />
                <TextField
                  label="Second Address"
                  placeholder="Enter second address"
                  type="text"
                  name="address2"
                  value={values.address2}
                  error={!!errors?.address2 && touched?.address2}
                  onChange={handleChange}
                  message={errors.address2}
                />
                <TextField
                  label="State"
                  placeholder="Enter state of origin"
                  type="text"
                  name="stateOfOrigin"
                  value={values.stateOfOrigin}
                  error={!!errors?.stateOfOrigin && touched?.stateOfOrigin}
                  onChange={handleChange}
                  message={errors.stateOfOrigin}
                />
                <TextField
                  label="City"
                  placeholder="Enter city"
                  type="text"
                  name="city"
                  value={values.city}
                  error={!!errors?.city && touched?.city}
                  onChange={handleChange}
                  message={errors.city}
                />
                <TextField
                  label="Passport Number"
                  placeholder="Enter passport number"
                  type="text"
                  name="passportNo"
                  value={values.passportNo}
                  error={!!errors?.passportNo && touched?.passportNo}
                  onChange={handleChange}
                  message={errors.passportNo}
                />
                <TextField
                  label="Address in Nigeria"
                  placeholder="Enter address in Nigeria"
                  type="text"
                  name="nigeriaAddress"
                  value={values.nigeriaAddress}
                  error={!!errors?.nigeriaAddress && touched?.nigeriaAddress}
                  onChange={handleChange}
                  message={errors.nigeriaAddress}
                />
              </FormContainer>
            </div>
            <Section className="mt-8">
              <DetailHeader title="Children Information" />
              <Section>
                <Section className="flex items-center my-6">
                  <div
                    className="text-primary cursor-pointer border border-primary px-4 py-2"
                    onClick={() => setAddChild(true)}
                  >
                    &oplus; Add Child
                  </div>
                </Section>
              </Section>
              <Section className="mt-6">
                <table className="min-w-full bg-white sm:px-6 lg:px-8 h-auto overflow-y-scroll relative">
                  <thead className="bg-[#F3F4F6]">
                    <tr className="w-full border-y border-light">
                      {tableHeading.map((item, idx) => {
                        return (
                          <th
                            className="text-left text-xs text-dark font-semibold uppercase whitespace-nowrap py-5 px-5"
                            key={idx}
                          >
                            {item}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {!fetchingChildren &&
                      children.siblings?.length > 0 &&
                      children?.siblings?.map(
                        (item: ChildrenProps, idx: Key) => {
                          return (
                            <tr className="w-full border-y border-light">
                              <td
                                className="text-sm font-normal capitalize whitespace-nowrap py-[14px] px-5"
                                key={item.id}
                              >
                                {item.firstname}
                              </td>
                              <td
                                className="text-sm font-normal capitalize whitespace-nowrap py-[14px] px-5"
                                key={idx}
                              >
                                {item.lastname}
                              </td>
                              <td
                                className="text-sm font-normal capitalize whitespace-nowrap py-[14px] px-5"
                                key={idx}
                              >
                                {item.dob}
                              </td>
                              <td
                                className="text-sm font-normal capitalize whitespace-nowrap py-[14px] px-5"
                                key={idx}
                              >
                                {item?.gender}
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
                {fetchingChildren && (
                  <Section className="h-64 w-full flex items-center justify-center">
                    <Section>
                      <BtnLoader />
                    </Section>
                  </Section>
                )}
                {!fetchingChildren && children?.siblings?.length === 0 && (
                  <Section className="h-64 w-full flex items-center justify-center flex-col">
                    <Paragraph className="text-gray-400">
                      {'Oops! No Data to Display'}
                    </Paragraph>
                    <Section>
                      <Section className="flex items-center my-6">
                        <div
                          className="text-primary cursor-pointer border border-primary px-4 py-2"
                          onClick={() => setAddChild(true)}
                        >
                          &oplus; Add Child
                        </div>
                      </Section>
                    </Section>
                  </Section>
                )}
              </Section>
            </Section>
          </div>
        </Section>
        <Section className="flex items-center my-6">
          <Button
            label="Update"
            className="bg-primary text-white lg:w-48"
            type="submit"
            loading={isLoading}
          />
        </Section>
      </Form>
    </div>
  );
};

export default ProfileModule;

export const tableHeading = ['FirstName', 'Lastname', 'Age', 'Gender'];
