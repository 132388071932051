import AppRoutes from '@components/router';
import { useEffect } from 'react';
import AOS from 'aos';
import withLoader from '@components/router/withLoader';

function App() {
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-cubic',
      once: true,
      offset: 50,
    });
  }, []);

  return (
    <>
      {/* // <Layout> */}
      {/* <AlertModal /> */}
      <AppRoutes />
      {/* // </Layout> */}
    </>
  );
}

export default App;
