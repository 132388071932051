// hello
import { Step } from '@/types/Step';
import StepperItem from '@components/elements/StepperItem';
import { useStepper } from '@hooks/useStepper';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

interface StepperProps {
  type?: string;
}

const Stepper: React.FC<StepperProps> = ({ type }) => {
  const { steps } = useStepper();
  const [search] = useSearchParams();

  const queryParams = search.get('step');

  return (
    <div className="lg:w-full w-full mx-auto">
      <div className="flex items-center justify-between w-full">
        {steps.map((item: Step, index: React.Key | null | undefined) => (
          <>
            <StepperItem
              label={item.step}
              active={item.step === Number(queryParams) || index === 0}
              completed={item.completed}
              highlighted={false}
              lastChild={index === steps.length - 1}
              key={index}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
